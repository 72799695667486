import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Card, Icon, Avatar, Col, Typography, Row,Button, Checkbox } from 'antd';
import Pagination from "react-js-pagination";
import pdf_icon from '../../../images/pdf_icon.png';
import erihplus from '../../../images/erihplus.png';
import index_copernicus from '../../../images/index_copernicus.png';
import ez3 from '../../../images/ez3.png';
import scipio from '../../../images/scipio.png';
// import { StickyContainer, Sticky } from 'react-sticky';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArchive} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import arhiva_background from '../../../images/arhiva_background.jpg'
// import { HeaderBackButton } from 'react-navigation';
import './Search.css'



library.add(faArchive)

// const Search = (props) => {
    // // return <button onClick={props.search}>Search</button>
    // return(
    //     <div className='search_page'>
    //         this is the search page
    //     </div>
    

// function Search(props){
function Search(){

    const { Title } = Typography
    const { Meta } = Card;
    
    const[activePage, setCurrentPage]=useState(1)
    // const [resultsPerPage, setresultsPerPage] = useState(1)
    const[checkBox,setCheckbox]=useState(false)
    const [color,setColor]=useState('red')
    const[hasSearch, setHasSearch]=useState(0)
    const[pressed,setPressed]=useState(false)
    const [show, setShow] = useState(false)
    const [results, setResults] = useState([])
    const [articole,setArticole]=useState([])
    const [autori,setAutori]=useState([])
    const [arhiva, setArhiva]=useState([])

    // const results = props.results
    // const results=props.resultList



    const resultsPerPage=20
    const indexOfLastRes = activePage*resultsPerPage;
    const indexOfFirstRes=indexOfLastRes-resultsPerPage;
    const currentResults = results.slice(indexOfFirstRes, indexOfLastRes);
    console.log('currentresults===',currentResults)

    const handlePageChange = ( pageNumber ) => {
        console.log( `active page is ${ pageNumber }` );
        setCurrentPage( pageNumber )
    };



    async function getArhiva(){
        axios.get('/api/arhiva/getArhiva')
            .then(response => {
                if (response.data.success) {
                    setArhiva(response.data.arhiva)
                    // setCards(response.data.arhiva)
                    let arhiva_server=response.data.arhiva

                    // setRevistaCurenta(arhiva_server[0].filename)
                    // setSelectedCards(response.data.arhiva)

                    // setCurrentCard(response.data.arhiva[response.data.arhiva.length-1].id_pontica)
                    // console.log('currentCards====',currentCards)
                    // get_authors_by_id_revista(currentCards)
                    // get_authors_by_id_revista(51)

                } else {
                    alert('Couldnt get blog`s lists')
                }
            })
    }

    async function getArticles(){
        await axios.get('/api/arhiva/getArticles')
        .then(response=>{
            if (response.data.success){
                setArticole(response.data.articole)
            }
        })
    }

    async function getAuthors(){
        await axios.get('/api/arhiva/getAuthors')
        .then(response=>{
            if (response.data.success){
                setAutori(response.data.autori)
            }
        })
    }

    async function LoadSearchResults(){
    
        console.log('window.location.pathname====',window.location.pathname)
        let myinput=window.location.pathname
        myinput=myinput.split('/')
        myinput=myinput[myinput.length-1]
        
        

        await axios.get('/api/arhiva/getSearch/'+myinput)
            .then(response => {
                if (response.data.success) {
                    // setArhiva(response.data.arhiva)
                    // setCards(response.data.arhiva)
                    setResults(response.data.search_result)
                } else {
                    alert('Couldnt get search data')
                }
            })
    }


    function display_search_results(){
        // DelayComponent()
        let mylist=[]
        
        if (currentResults!=undefined){
            for (let i = 0; i < currentResults.length; i++) {
                const element_curent = currentResults[i];
                const articol=articole[element_curent.nr_articol-1]
                
                let indice=element_curent.nr_articol
                var regExp = /^0[0-9].*$/
                if (regExp.test(indice)) //verific daca incepe cu 0
                indice =indice.substring(1) // scot 0 de la inceput

                if (indice<1477){
                    // if(i %2==0){
                    //     let div_rezultat=
                    //         <div className='element_curent_div_gri'>
                    //             <a href={"https://minac.ro/pdf/articole/"+ articole[indice-1].filename}> 
                    //                 <span className='element_curent element_curent_titlu'> {articole[indice-1].titlu_articol}</span>
                    //                 <br/>
                    //                 <span className='element_curent'>Numar Articol: {element_curent.nr_articol}</span>
                    //                 <span className='element_curent'>Autori: {articole[indice-1].autori}</span>
                    //                 <span className='element_curent'>Volum: {articole[indice-1].nr_pontica}</span>
                    //                 <span className='element_curent'>An: {articole[indice-1].an}</span>
                                    
                    //             </a>
                    //         </div>

                    //     mylist.push(div_rezultat)
                    // }
                    // else{
                    //     let div_rezultat=
                    //         <div className='element_curent_div'>
                    //             <a href={"https://minac.ro/pdf/articole/"+ articole[indice-1].filename}> 
                    //             <span className='element_curent element_curent_titlu'> {articole[indice-1].titlu_articol}</span>
                    //             <br/>
                    //             <span className='element_curent'>Numar Articol :{element_curent.nr_articol}</span>
                    //             <span className='element_curent'>Autori :{articole[indice-1].autori}</span>
                    //             <span className='element_curent'>Volum :{articole[indice-1].nr_pontica}</span>
                    //             <span className='element_curent'>An: {articole[indice-1].an}</span></a>
                    //         </div>
                    //     mylist.push(div_rezultat)
                    // }


                    let div_rezultat=<div className='casuta_afisare_filtru'>
                        {i %2 == 0 && 
                        <div className='articole_filtrate_container'>
                            <div className='numar_articol_filtrat'>{articol.nr_articol}</div>
                            <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-articol.id_pontica].filename} </div>
                            <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>
                            <table className='autori_articol_filtrat'>
                                <tbody>
                                    <tr>
                                        <td >
                                            <div className='container_autor_filtrat'>
                                                {display_autori(articol.autori)}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}

                        {i %2 != 0 && 
                        <div className='articole_filtrate_container_gri'>
                            <div className='numar_articol_filtrat'>{articol.nr_articol}</div>
                            
                            <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-articol.id_pontica].filename} </div>
                            <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>
                            <table className='autori_articol_filtrat'>
                                <tbody>
                                    <tr>
                                        <td >
                                            <div className='container_autor_filtrat'>
                                                {display_autori(articol.autori)}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}
                    </div>

                    mylist.push(div_rezultat)
                }
            }
        }
        return mylist
    }

    const navigationOptions = ({navigation}) => {
        // return{
        //     headerLeft:(<HeaderBackButton onPress={mergi_inapoi_la_arhiva}/>)
        // }
    }


    function display_autori(autori_cu_virgula){
        autori_cu_virgula=autori_cu_virgula.replace(', ',',')
        let lista_autori=autori_cu_virgula.split(',')
        let lista_finala=[]

        for (let i = 0; i < lista_autori.length; i++) {
            const autor = lista_autori[i];
            let vect = autor.split(' ')
            let prenume_autor=vect[0]


            // console.log('autor===',autor)
            // console.log('i=====',i)

            let restul_numelui=autor.replace(prenume_autor,'')
            // prenume_autor=prenume_autor.toLowerCase().charAt(0).toUpperCase()
            prenume_autor = prenume_autor.charAt(0).toUpperCase()+prenume_autor.substring(1).toLowerCase()
            if (prenume_autor.includes('-')){
                let vect=prenume_autor.split('-')

                prenume_autor=''
                for (let j=0; j<vect.length-1; j++){
                    prenume_autor+=vect[j].charAt(0).toUpperCase()+vect[j].substring(1).toLowerCase()+'-'
                }
                prenume_autor+=vect[vect.length-1].charAt(0).toUpperCase()+vect[vect.length-1].substring(1).toLowerCase()

                // prenume_autor=vect[0].charAt(0).toUpperCase()+'-'+vect[1].charAt(0).toUpperCase()
            }


            if (i==lista_autori.length-1){
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{prenume_autor+' '+restul_numelui}</a>
                // let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{autor}</a>
                lista_finala.push(autor_link)
                // lista_finala.push(<span>,</span>)
            }
            else{
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{prenume_autor +' '+ restul_numelui},</a>
                // let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{autor},</a>
                lista_finala.push(autor_link)
                // lista_finala.push(<span>,</span>)
            }
        }
        return lista_finala
    }

    useEffect( ()=>{
        LoadSearchResults();
        getArhiva();
        getArticles();
        getAuthors();

    },[])

    useEffect( ()=>{
        var children_list = document.querySelector(".pagination").childNodes;
        if (children_list!= null && children_list !=undefined){
            
            children_list[0].innerText='';
            children_list[1].innerText='';
            children_list[children_list.length-1].innerText='';
            children_list[children_list.length-2].innerText='';

            children_list[0].className+='display_none';
            children_list[1].className+='display_none';
            children_list[children_list.length-1].className='display_none';
            children_list[children_list.length-2].className='display_none';


            
            children_list[0].className='sageti_search';
            children_list[1].className='sageti_search';
            children_list[children_list.length-1].innerHtml='sageti_search';
            children_list[children_list.length-2].innerHtml='sageti_search';

            for (let i = 2; i < children_list.length-2; i++) {
                var child = children_list[i];
                
                if (child!=undefined){
                    let filename_details='PAGINA '+(i-1)
                    child.innerText=filename_details
                }
            }
        }
    })


    const renderResults=currentResults.map((result,index)=>{
        return <div>
                    {result.nr_articol}
                </div>
    })

    if(results!=[]){
        return (
            <div className='bigContainer bigContainerSearch'>
                <div className='arhiva_header underline_container_redactie'>
                    <div className='logo_and_title_search'>
                        <div className="separator_search">
                            <FontAwesomeIcon className= 'iconita_arhiva' icon="search" style={{color:'#1dbc60'}} /> 
                            <h1>Rezultatele Cautarii:</h1>
                            <span className="underline_recenzare underline_search"/>
                            <span style={{color:'#1dbc60'}}>✻</span>
                            <span className="underline_recenzare underline_search"/>
                        </div>
                    </div>
                </div>
                <div className='smallContainer container_search_page'>
                    <div className='left_column left_search'>
                        {<div className="pagination_container pagination_search_container">
                            <Pagination
                            activePage={ activePage }
                            itemsCountPerPage={ 20 }
                            totalItemsCount={ results.length }
                            pageRangeDisplayed={ 100 }
                            onChange={ handlePageChange }
                            />
                        </div>}
                    </div>
                    <div className='display_search_results '>
                        {
                            ((results.length==0)   &&   
                            <h1 className='titlu_rezultate'> Se incarca rezultatele...</h1>) || 
                            (<h1 className='titlu_rezultate'>{results.length} Rezultate:</h1> )
                        }
                        {/* {renderResults} */}
                        {display_search_results()}
                    </div>
                </div>
            </div>
        )
    }
    else{
        return <div>
            Loading...
        </div>
    }
} export default Search


