import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Card, Icon, Avatar, Col, Typography, Row } from 'antd';
import Pagination from "react-js-pagination";
import './PaginaAdmin.css';
import { Menu } from 'antd';
import { USER_SERVER } from '../../Config';
import { withRouter } from 'react-router-dom';
import { useSelector } from "react-redux";
import {  NavLink } from 'react-router-dom';


const { Title } = Typography
const { Meta } = Card;




    
function PaginaAdmin(props) {

    

    const user = useSelector(state => state.user)

    const logoutHandler = () => {
        setTimeout(()=>{
            console.log('am intrat in logout handler')
            axios.get(`${USER_SERVER}/logout`).then(response => {
                console.log('response.status===',response.status)
            if (response.status === 200) {
                props.history.push("/login");
            alert('logout successful')
            } else {
                alert('Log Out Failed')
            }
            });
        },1000)
    };


    console.log('user.userData===',user.userData)

    if (user.userData && !user.userData.isAuth) {
        return (
        <div  className='meniu_admin'  >
                <div className='admin_buttons'> 
                    <a href={"/login"}>LOGIN</a>
                </div>

                {/* <div className='admin_buttons'>
                    <a href={'/register'}>INREGISTRARE</a>
                </div> */}
        </div>
        )


    } else {
        return (
        <div className='meniu_admin'>
            <div className='meniu_admin_subdiv'>

                <div className='admin_buttons'>
                    <a href={"/arhiva/create"}>CREARE VOLUM</a>
                </div>

                <div className='admin_buttons'>
                    <a href={"/arhiva/edit"}>EDITARE VOLUME</a>
                </div>

                <div className='admin_buttons'>
                    <a href={"/blog/create"}>CREARE ARTICOL</a>
                </div>


                <div className='admin_buttons'>
                    <a href={"/blog/editare_articole"}>EDITARE ARTICOLE</a>
                </div>

                <div className='admin_buttons'>
                    <a href={"/blog/editare_autori"}>EDITARE AUTORI</a>
                </div>

            


                <div onClick = {logoutHandler} className='admin_buttons'>
                    {/* <a href={"/logout"}>LOGOUT</a> */}
                    <a>LOGOUT</a>
                </div>

            </div>
        </div>
        )
    }
} export default PaginaAdmin






