import React, { useState, useEffect } from 'react';
import RightMenu from './Sections/RightMenu';
import { Drawer, Button, Icon } from 'antd';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Sections/Navbar.css';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

library.add(faBars)


function NavBar() {
    const [visible, setVisible] = useState(false)
    const [show, setShow] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);

    
    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 500)
    }, [show])

    useEffect(() => {
        // if (width<680){

        // }
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize)
    },[]);
    
    
    

    



    const showDrawer = () => {
        setVisible(true)
    };

    const onClose = () => {
        setVisible(false)
    };



    // if(!show) return(
    //     <div></div>
    // )

    return  (
        
        // <nav className="menu" style={{ zIndex: 1, width: '100%' }}>
        // <div className="menu__logo">
        //     <a href="/">Logo</a>
        // </div>
        // <div className="menu__container"> 
        //     <div className="menu_left">
        //     <LeftMenu mode="horizontal" />
        //     </div>
        //     <div className="menu_rigth">
        //     <RightMenu mode="horizontal" />
        //     </div>
        //     <Button
        //     className="menu__mobile-button"
        //     type="primary"
        //     onClick={showDrawer}
        //     >
        //     <Icon type="align-right" />
        //     </Button>
        //     <Drawer
        //     title="Meniu"
        //     placement="right"
        //     className="menu_drawer"
        //     closable={false}
        //     onClose={onClose}
        //     visible={visible}
        //     >
        //     <LeftMenu mode="inline" /> 
        //     <RightMenu mode="inline" />
        //     <Meniu/>
        //     </Drawer>
        //     <div>
        //         <Meniu/>
        //     </div>
        // </div>
        // </nav>





        <ul className="nav" >
            <div className="logo_container">
                <div className="menu__logo">
                    <a className="center_link" href="/">
                        {/* <img src="../../../images/logo.png" alt=""/> */}
                    </a>
                    
                </div>

            <p>Muzeul de Istorie Nationala si Arheologie Constanta</p>
            </div>
        
            {/* <div className="menu__container">  */}
            {/* <div className="menu_left"> */}
            {/* <div className="nav_links"> */}


            {/* <LeftMenu mode="horizontal" /> */}
            {/* </div> */}
            {/* <div className="menu_rigth"> */}






            

            { (isMobile) ?
                ((width<768)? 
                    <div></div>
                        :
                    (<RightMenu mode="horizontal" />)
                )
                    :
                ((width < 768)  ?
                    (<div></div>) 
                        : 
                    (<RightMenu mode="horizontal" />)
                )
            }
            {/* </div> */}

            <Button
                className="menu__mobile-button"
                type="primary"
                onClick={showDrawer}
                >
                <FontAwesomeIcon className='iconita_drawer' icon="bars" />
            </Button>

            <Drawer
                title="MENIU"
                placement="right"
                className="menu_drawer"
                closable={false}
                onClose={onClose}
                visible={visible}
                className='drawer'
            >
                {/* <LeftMenu mode="inline" />  */}
                <RightMenu mode="inline" />
                {/* <Meniu/> */}
            </Drawer>
            
        {/* </div> */}
            {/* </div> */}
        </ul>
    )
}

export default NavBar


// 750
// 750
// 750
// 750
// 750
// 750