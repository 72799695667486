import React, { useState, useEffect, useRef } from 'react'

import QuillEditor from '../../editor/QuillEditor';
import { Typography, Button, Form, message } from 'antd';
import axios from 'axios';
import { useSelector } from "react-redux";
import './CreateVolum.css';

const { Title } = Typography;

function CreateVolum(props) {
    const user = useSelector(state => state.user);

    const [content, setContent] = useState("")
    const [an, setAn]=useState("")
    const [nr_pontica, setNrPontica  ] = useState("")
    const [ files, setFiles] = useState([])
    const [arhiva, setArhiva]=useState([])
    const [show_confirm_msg,setShowConfirmMsg]=useState(false)
    const [filename, setFilename] =useState(false)
    const form = useRef();
    const [id_pontica, setIdPontica]=useState('')
    const [tip_volum, setTipVolum]=useState('')
    const [denumire_volum, setDenumireVolum]=useState('')
    const [nr_pontica_roman, setNrPonticaRoman]=useState('')
    const [nr_pagini, setNrPagini]=useState('')
    const [nr_colite, setNrColite]=useState('')
    const [nr_planse, setNrPlanse]=useState('')
    const [nr_articole, setNrArticole]=useState('')
    const [issn, setIssn ]=useState('')
    const [e_issn, setEissn ]=useState('')
    const [editura_nume, setEdituraNume ]=useState('')
    const [editura_localitate, setEdituraLocalitate  ]=useState('')

    



    
    useEffect(()=>{
        getArhiva()

    },[])

    useEffect(()=>{
        let casuta_id_pontica = document.getElementById('id_pontica_id')
        if(casuta_id_pontica !=null){
            console.log('casuta_id_pontica===',casuta_id_pontica.value)
            setIdPontica(parseInt(casuta_id_pontica.value))
            console.log('id_pontica===',id_pontica)
        }
    })


    async function getArhiva(){
        axios.get('/api/arhiva/getArhiva')
            .then(response => {
                if (response.data.success) {
                    setArhiva(response.data.arhiva)

                } else {
                    alert('Couldnt get blog`s lists')
                }
            })
    }

    
    const onEditorChange = (value) => {
        setContent(value)
        // setAn(value)
        console.log(content)
        // console.log(an)
    }


    const onFilesChange = (files) => {
        setFiles(files)
    }

    const onSubmit = (event) => {
        event.preventDefault();

        // setContent("");
        // setAn("");

        if (user.userData && !user.userData.isAuth) {
            return alert('Please Log in first');
        }

        
        const variables = {

            content: content,
            an:an,
            nr_pontica:nr_pontica,
            id_pontica:id_pontica,
            writer:user.userData._id,
            tip_volum:tip_volum,
            denumire_volum:denumire_volum,
            filename:filename,
            nr_pontica_roman:nr_pontica_roman,
            nr_pagini:nr_pagini,
            nr_colite:nr_colite,
            nr_planse:nr_planse,
            nr_articole:nr_articole,
            issn:issn,
            e_issn:e_issn,
            editura_nume:editura_nume,
            editura_localitate:editura_localitate,
        }

        console.log('variables===',variables)





        axios.post('/api/arhiva/createVolum', variables)
            .then(response => {
                if (response) {
                    message.success('Arhiva Created!');

                    setTimeout(() => {
                        props.history.push('/arhiva')
                    }, 2000);
                }
            })
    }




    const onChangeDetails = (event) => {
        event.preventDefault();

        // setAn("");

        if (user.userData && !user.userData.isAuth) {
            return alert('Please Log in first');
        }

        let name=event.target.name
        let value=event.target.value
        if (name=='an'){
            setAn(value)
        }
        else if (name=='nr_pontica'){
            setNrPontica(value)
        }
    }


    const handleConfirmMsg = (e) => {
        e.preventDefault();
        setShowConfirmMsg(true);
    };

    const handleCancelMsg=(e)=>{
        e.preventDefault();
        setShowConfirmMsg(false);
    }

    function handleSetState(mySetFunction, text_input, event) {
        // const value = event.currentTarget.value;
        console.log('mySetFunction===',mySetFunction)
        mySetFunction(text_input);
    }

    const onKeyDown = function(e, id_element, mySetFunction) {
        console.log('id_element===',id_element)
        if (e.keyCode === 8) {
            let text_input=''
            setTimeout(() => {
                text_input=document.getElementById(id_element).value
                // setDescriptori(text_input)
                handleSetState(mySetFunction, text_input, e)
            }, 100);
        }
    }











    return (
        <div className='editare_articol_big_container'>
                <h1 className='titlu_mare_editare_articol'> Creare Volum</h1>

                {arhiva.length>50 && 
                
                    <form ref={form} onSubmit={handleConfirmMsg}>







                        <div className='nr_pontica_div'>
                            <label className='nr_pontica_label'>Numar Pontica</label>
                            <input 
                                className='nr_pontica_input'
                                id='nr_pontica_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setNrPontica(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'nr_pontica_id',setNrPontica)}}

                                type="text" 
                                name="nr_pontica"
                            />
                        </div>


                        <div className='anul'>
                            <label className='anul_label'>Anul</label>
                            <input 
                                className='anul_input'
                                id='anul_input_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setAn(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'anul_input_id',setAn)}}
                                type="text" 
                                name="an"
                            />
                        </div>


                        <div className='filename_div'>
                            <label className='filename_label' >Filename</label>
                            <textarea
                                className='filename_input'
                                id='filename_input_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setFilename(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'filename_input_id',setFilename)}}
                                placeHolder='ex: Pontica 26 (1993)   sau   Pontica 46 Supplementum II (2013)'
                                type="text" 
                                name="filename"
                            />
                        </div>


                        <div className='id_pontica_div'>
                            <label className='id_pontica_div' >ID Pontica</label>
                            <input
                                className='in_pontica_input'
                                id='id_pontica_id'
                                size="large"
                                htmlType="submit"
                                // onChange={event=>setIdPontica(parseInt(event.target.value))}
                                // onKeyDown={(event)=>{onKeyDown(event,'id_pontica_id',setIdPontica)}}
                                value={parseInt(arhiva[0].id_pontica)+1}
                                
                                type="text" 
                                name="id_pontica"
                            />
                        </div>


                        <div className='tip_volum_div'>
                            <label className='tip_volum_label' >Tip Volum</label>
                            <input
                                className='tip_volum_input'
                                id='tip_volum_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setTipVolum(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'tip_volum_id',setTipVolum)}}
                                type="text" 
                                name="tip_volum"
                            />
                        </div>


                        <div className='denumire_volum_div'>
                            <label className='denumire_volum_label' >Denumire Volum</label>
                            <input
                                className='denumire_volum_input'
                                id='denumire_volum_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setDenumireVolum(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'denumire_volum_id',setDenumireVolum)}}
                                type="text" 
                                name="denumire_volum"
                            />
                        </div>


                        <div className='numar_pontica_roman_div'>
                            <label className='numar_pontica_roman_label' >Numar Pontica Roman</label>
                            <input
                                className='numar_pontica_roman_input'
                                id='numar_pontica_roman_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setNrPonticaRoman(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'numar_pontica_roman_id',setNrPonticaRoman)}}
                                type="text" 
                                name="numar_pontica_roman"
                            />
                        </div>


                        <div className='numar_pagini_div'>
                            <label className='numar_pagini_label' >Numar Pagini</label>
                            <input
                                className='numar_pagini_input'
                                id='numar_pagini_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setNrPagini(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'numar_pagini_id',setNrPagini)}}
                                type="text" 
                                name="numar_pagini"
                            />
                        </div>


                        <div className='numar_colite_div'>
                            <label className='numar_colite_label' >Numar Colite</label>
                            <input
                                className='numar_colite_input'
                                id='numar_colite_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setNrColite(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'numar_colite_id',setNrColite)}}
                                type="text" 
                                name="numar_colite"
                            />
                        </div>


                        <div className='numar_planse_div'>
                            <label className='numar_planse_label' >Numar Planse</label>
                            <input
                                className='numar_planse_input'
                                id='numar_planse_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setNrPlanse(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'numar_planse_id',setNrPlanse)}}
                                type="text" 
                                name="numar_planse"
                            />
                        </div>


                        <div className='numar_articole_div'>
                            <label className='numar_articole_label' >Numar Articole</label>
                            <input
                                className='numar_articole_input'
                                id='numar_articole_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setNrArticole(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'numar_articole_id',setNrArticole)}}
                                type="text" 
                                name="numar_articole"
                            />
                        </div>


                        <div className='issn_div'>
                            <label className='issn_label' >ISSN</label>
                            <input
                                className='issn_input'
                                id='issn_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setIssn(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'issn_id',setIssn)}}
                                type="text" 
                                name="issn"
                            />
                        </div>


                        <div className='e_issn_div'>
                            <label className='e_issn_label' >E-ISSN</label>
                            <input
                                className='e_issn_input'
                                id='e_issn_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setEissn(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'e_issn_id',setEissn)}}
                                type="text" 
                                name="e_issn"
                            />
                        </div>


                        <div className='editura_nume_div'>
                            <label className='editura_nume_label' >Editura Nume</label>
                            <input
                                className='editura_nume_input'
                                id='editura_nume_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setEdituraNume(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'editura_nume_id',setEdituraNume)}}
                                type="text" 
                                name="editura_nume"
                            />
                        </div>


                        <div className='editura_localitate_div'>
                            <label className='editura_localitate_label' >Editura Localitate</label>
                            <input
                                className='editura_localitate_input'
                                id='editura_localitate_id'
                                size="large"
                                htmlType="submit"
                                onChange={event=>setEdituraLocalitate(event.target.value)}
                                onKeyDown={(event)=>{onKeyDown(event,'editura_localitate_id',setEdituraLocalitate)}}
                                type="text" 
                                name="editura_localitate"
                            />
                        </div>





                        <div className='editare_submit_div' >
                            <button
                                htmlType="submit"
                                className="editare_submit"
                                onSubmit={handleConfirmMsg}>
                                SUBMIT
                            </button>
                        </div>

                    </form>
                }


                    {show_confirm_msg && (
                        <div className='mesaj_confirmare'> 
                            <br/> Esti sigur ca vrei sa creezi volumul? {" "}
                            <button className='submit_raspuns' onClick={(event)=>onSubmit(event)}> 
                                DA
                            </button>

                            <button className='submit_raspuns' onClick={(event)=>handleCancelMsg(event)}> 
                                NU
                            </button>
                        </div> 
                    )}
        </div>
    )
}

export default CreateVolum
