import React, { isValidElement, useEffect, useState } from 'react'
import axios from 'axios';
import { Typography } from 'antd';
import pdf_icon from '../../../images/pdf_icon.png';
import './PostPage.css'
const { Title } = Typography

function PostPage(props) {
    const [post, setPost] = useState([])
    const [arhiva, setArhiva]=useState([])
    const [text_articol, setTextArticol]=useState([])
    const postId = props.match.params.postId;

    useEffect(() => {
        const variable = { postId: postId }

        axios.post('/api/blog/getPost', variable)
            .then(response => {
                if (response.data.success) {
                    setPost(response.data.post)

                    axios.get('/api/blog/getText/'+response.data.post.nr_articol)
                    .then(res => {
                        if (res.data.success) {
                            setTextArticol(res.data.text_articol.text_articol)
                        } else {
                            alert('Couldnt get text')
                        }
                    })
                } else {
                    alert('Couldnt get post')
                }
            })
    }, [postId])

    //daca vreau sa obtin textul articolului
    // useEffect(() => {
    //     // const variable = { postId: postId }
    //     post != undefined && axios.get('/api/blog/getText/'+post.nr_pontica)
    //         .then(response => {
    //             if (response.data.success) {
    //                 setTextArticol(response.data.text_articol)
    //             } else {
    //                 alert('Couldnt get text')
    //             }
    //         })
    // }, [])

    useEffect(() => {
        // getArticles();
        axios.get('/api/arhiva/getArhiva')
            .then(response => {
                if (response.data.success) {
                    setArhiva(response.data.arhiva)
                    // setCards(response.data.arhiva)
                } else {
                    alert('Couldnt get blog`s lists')
                }
            })
    },[])

    // if (post.writer) {
        let indice_arhiva=-1
        for (let i = 0; i < arhiva.length; i++) {
            var element = arhiva[i];
            if (element.id_pontica==post.id_pontica){
                indice_arhiva=i
                break;
            }
        }

        let titlu=''
        if (indice_arhiva!=-1){
            titlu='REVISTA '+(arhiva[indice_arhiva].filename).toUpperCase()
        }
        
        let link_descarcare='https://minac.ro/pdf/articole/'+ post.filename

        return (
            <div className="post_page">
                <div className='casuta_post'>
                    {arhiva!='undefined' &&  indice_arhiva!=-1 && 
                    <span className='revista_titlu'>{titlu}</span>}
                    {post.titlu_articol!='' && <h2 className='titlu_articol_post'>{post.titlu_articol}</h2>}
                    {post.titlu_articol_en !='' && <h2 className='titlu_articol_post'>{post.titlu_articol_en}</h2>}

                    <div className='coperta_si_detalii_articol'>
                        { arhiva!=undefined && indice_arhiva!=-1 
                        && <img className='coperti' src={"https://minac.ro/pdf/coperti/"+arhiva[indice_arhiva].filename+".jpg"}  /> }

                        <div className='detalii_volum_container_articol'>
                            <div className='detalii_volum_container_articol_mic'>
                                <span className='detalii_de_la_server_articol'>Autori <span className='span_articol'> {post.autori}</span></span>
                                {post.sectiunea != '' && <span className='detalii_de_la_server_articol'>Sectiunea <span className='span_articol'>{post.sectiunea}</span></span>}
                                <span className='detalii_de_la_server_articol'>Numar articol <span className='span_articol'> {post.nr_articol}</span></span>
                                {post.descriptori != '' && <span className='detalii_de_la_server_articol'>Descriptori <span className='span_articol'>{post.descriptori}</span></span>}
                                {post.limba!='' && <span className='detalii_de_la_server_articol'>Limba de redactare <span className='span_articol'>{post.limba}</span></span>}
                                {post.paginatie!='' && <span className='detalii_de_la_server_articol'>Paginatie <span className='span_articol'>{post.paginatie}</span></span>}
                                
                                {post.cuvinte_cheie !='' && <span className='detalii_de_la_server_articol'>Cuvinte Cheie<span className='span_articol cuvinte_cheie'>{post.cuvinte_cheie}</span></span>}

                                {post.cuvinte_cheie_en !='' && <span className='detalii_de_la_server_articol'>Cuvinte Cheie EN<span className='span_articol cuvinte_cheie'>{post.cuvinte_cheie_en}</span></span>}
                                {/* <p>{post.text_articol}</p> */}


                                {post.rezumat !='' && <span className='detalii_de_la_server_articol'>Rezumat   <span className='span_articol'>{post.rezumat} </span></span>}

                                {post.rezumat_en !='' && <span className='detalii_de_la_server_articol'>Rezumat EN   <span className='span_articol'>{post.rezumat_en} </span></span>}

                                {post.numar_pagini!='' && <span className='detalii_de_la_server_articol'>Numar Pagini<span className='span_articol'>{post.numar_pagini}</span></span>}

                                {/* {arhiva.length>50 && console.log('editura_nume arhiva===',arhiva[indice_arhiva].editura_nume)} */}

                                {arhiva.length>50 && <span className='detalii_de_la_server_articol'>Editura Volum <span className='span_articol'>{arhiva[indice_arhiva].editura_nume}</span></span>}

                                {arhiva.length>50 && <span className='detalii_de_la_server_articol'>Localitatea De Publicare <span className='span_articol'>{arhiva[indice_arhiva].editura_localitate}</span></span>}

                                {arhiva.length>50 && <span className='detalii_de_la_server_articol'>ISSN Volum<span className='span_articol'>{arhiva[indice_arhiva].issn}</span></span>}

                                



                                <div className='descarca_articol'><b>Descarca fisierul</b>
                                    <span className='span_articol' ><a href={link_descarcare}>
                                        <img className='pdf_icon' src={pdf_icon}/>
                                    </a></span>
                                </div>
                                {/* <p>{text_articol}</p> */}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    // } else {
        // return (
        //     <div style={{ width: '80%', margin: '3rem auto' }}>loading...</div>
        // )
    // }

}

export default PostPage
