/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Menu } from 'antd';
import axios from 'axios';
import { USER_SERVER } from '../../../Config';
import { withRouter } from 'react-router-dom';
import { useSelector } from "react-redux";
import {  NavLink } from 'react-router-dom';
import './Navbar.css'

//am pus toate butoanele din left menu in right menu
function RightMenu(props) {
  const user = useSelector(state => state.user)

  const logoutHandler = () => {
    axios.get(`${USER_SERVER}/logout`).then(response => {
      if (response.status === 200) {
        props.history.push("/login");
      } else {
        alert('Log Out Failed')
      }
    });
  };

  if (user.userData && !user.userData.isAuth) {
    return (
      <Menu  mode={props.mode} >
        <Menu.Item >
          <NavLink 
            // end to="/"
            // to="/"
            style={{color:"black"}}
            exact to="/"
            activeClassName="active_link">
            ACASA
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item >
        {/* <Menu.Item  >
          <a href="https://revistapontica.wordpress.com" style={{color:"black"}}>
            ARHIVA PONTICA
          </a>
        </Menu.Item > */}
        <Menu.Item key="arhiva"  >
          <NavLink 
            style={{color:"black"}}
            exact to="/arhiva"
            activeClassName="active_link">
            ARHIVA PONTICA
          </NavLink>
          {/* <a href="/blog">Blog</a> */}
        </Menu.Item>


        {/* 
        <Menu.Item key="blog"  >
          <NavLink 
            // end to="/blog"
            // to="/blog"
            style={{color:"black"}}
            exact to="/blog"
            activeClassName="active_link">
            ARTICOLE
          </NavLink>
        </Menu.Item> */}


        <Menu.Item  >
          <NavLink 
            style={{color:"black"}}
            exact to ="/blog"
            activeClassName="active_link">
            ARTICOLE
          </NavLink>
        </Menu.Item>

        <Menu.Item>
          <NavLink 
            // to="/"
            // end to ="/"
            style={{color:"black"}}
            exact to ="/linkuri"
            activeClassName="active_link">
            LINKURI
          </NavLink>
        </Menu.Item>

        <Menu.Item  >
          <NavLink 
            // to="/"
            // end to ="/"
            style={{color:"black"}}
            exact to ="/norme"
            activeClassName="active_link">
            NORME DE REDACTARE
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>
        <Menu.Item  >
          <NavLink 
            // to="/"
            // end to ="/"
            style={{color:"black"}}
            exact to ="/recenzare"
            activeClassName="active_link">
            PROCESUL DE RECENZARE
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>
        <Menu.Item>
          <NavLink 
            // to="/"
            // end to ="/"
            style={{color:"black"}}
            exact to ="/redactia"
            activeClassName="active_link">
            COLEGIUL EDITORIAL
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>
        <Menu.Item  >
          <NavLink 
            // to="/"
            // end to ="/"
            style={{color:"black"}}
            exact to ="/contact"
            activeClassName="active_link">
            CONTACT
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>

        <Menu.Item  >
          <NavLink  
            style={{color:"black"}}
            exact to ="/admin"
            activeClassName="active_link"
            className='admin'>
            ADMIN
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>
        {/* <Menu.Item key="login" >
          <NavLink  
            // end to ="/login"
            // to="/login"
            style={{color:"black"}}
            exact to ="/login"
            activeClassName="active_link"> 
            LOGIN
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="app" >
          <NavLink
            // end to = "/register"
            // to="/register"
            className='inregistrare'
            style={{color:"black"}}
            exact to="/register"
            activeClassName="active_link">
            INREGISTRARE
          </NavLink>
        </Menu.Item> */}
      </Menu>


    )
  } else {
    return (
      <Menu mode={props.mode} >


        <Menu.Item >
          <NavLink 
            // to="/"
            // end to ="/"
            style={{color:"black"}}
            exact to ="/"
            activeClassName="active_link">
            ACASA
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>
        {/* <Menu.Item  >
          <a href="https://revistapontica.wordpress.com" style={{color:"black"}}>
            ARHIVA PONTICA
          </a>
        </Menu.Item > */}
        <Menu.Item key="arhiva"  >
          <NavLink 
            style={{color:"black"}}
            exact to="/arhiva"
            activeClassName="active_link">
            ARHIVA PONTICA
          </NavLink>
          {/* <a href="/blog">Blog</a> */}
        </Menu.Item>
        {/* <Menu.Item key="create_article" >
          <NavLink 
            // to="/blog/create"
            // end to="/blog/create"
            style={{color:"black"}}
            exact to="/blog/create"
            activeClassName="active_link">
            SCRIE ARTICOL
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="creare_arhiva" >
          <NavLink 
            // to="/blog/create"
            // end to="/blog/create"
            style={{color:"black"}}
            exact to="/arhiva/create"
            activeClassName="active_link">
            CREARE ARHIVA
          </NavLink>
        </Menu.Item> */}

        <Menu.Item  >
          <NavLink 
            style={{color:"black"}}
            exact to ="/blog"
            activeClassName="active_link">
            ARTICOLE
          </NavLink>
        </Menu.Item>

        <Menu.Item>
          <NavLink 
            // to="/"
            // end to ="/"
            style={{color:"black"}}
            exact to ="/linkuri"
            activeClassName="active_link">
            LINKURI
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>


        {/* <Menu.Item key="blog" >
          <NavLink 
            // to="/blog"
            // end to="/blog"
            style={{color:"black"}}
            exact to="/blog"
            activeClassName="active_link">
            ARTICOLE
          </NavLink>
        </Menu.Item> */}

        
        <Menu.Item >
          <NavLink 
            // to="/"
            // end to ="/"
            style={{color:"black"}}
            exact to ="/norme"
            activeClassName="active_link">
            NORME DE REDACTARE
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>
        <Menu.Item >
          <NavLink 
            // to="/"
            // end to ="/"
            style={{color:"black"}}
            exact to ="/recenzare"
            activeClassName="active_link">
            PROCESUL DE RECENZARE
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>
        <Menu.Item >
          <NavLink 
            // to="/"
            // end to ="/"
            style={{color:"black"}}
            exact to ="/redactia"
            activeClassName="active_link">
            COLEGIUL EDITORIAL
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>
        <Menu.Item >
          <NavLink 
            className='contact'
            style={{color:"black"}}
            exact to ="/contact"
            activeClassName="active_link">
            CONTACT
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>
        <Menu.Item  >
          <NavLink  
            style={{color:"black"}}
            exact to ="/admin"
            activeClassName="active_link"
            className='admin'>
            ADMIN
          </NavLink>
          {/* <a href="/">Home</a> */}
        </Menu.Item>

        {/* <Menu.Item key="logout" >
          <NavLink onClick = {logoutHandler}
            // to="/logout"
            // end to="/logout"
            className='logout'
            style={{color:"black"}}
            exact to="/logout"
            activeClassName="active_link">
            LOGOUT
          </NavLink>
        </Menu.Item> */}
      </Menu>
    )
  }
}

export default withRouter(RightMenu);

