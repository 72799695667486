import './ProcesRecenzare.css';
import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fab, faCheckSquare, faCoffee,faPencilAlt, faSearch)


class ProcesRecenzare extends React.Component{
    constructor(props){
        super(props);
        this.state={
            message:'success',
            // id_ultimul_articol:'',
            // link_ultimul_articol:''
        }
        // this.get_latest_revista=this.get_latest_revista.bind(this)
    }


    handleChange(e){
        let name=e.target.name;
        let value=e.target.value;

        if(name==='firstName'){
            this.setState({
                firstName:value
            })
        }
        if(name==='lastName'){
            this.setState({
                lastName:value
            })
        }
        if(name==='phoneNumber'){
            this.setState({
                phoneNumber:value
            })
        }
        
        console.log(name,value)
    }



    componentDidMount(){
        
    }

    render(){
        let message=this.state.message

        
        if (message==='success' ){
            return (
                    <div className ="wrapper">
                        <div className="inner_wrapper">
                            
                            <div className="underline_container_recenzare">
                                <h2><FontAwesomeIcon className= "myicon" icon="search" style={{color:'#1dbc60'}} /> </h2>
                                <div className="separator_recenzare">
                                    <h2 className="procesul">Procesul de Recenzare</h2>
                                    <span className="underline_recenzare"/>
                                    <span className='steluta' style={{color:'#1dbc60'}}>✻</span>
                                    <span className="underline_recenzare"/>
                                </div>
                            </div>
                            <p> 
                            <FontAwesomeIcon icon="check-square" className="icon_check" />Odată ce etapa de centralizare a articolelor trimise spre publicare este încheiată, acestea vor fi trimise spre recenzare unor personalități din domeniul științific, atat din țară cât și din străinătate.
                            În procesul de recenzare se au în vedere calitatea ştiinţifică a articolelor, veridicitatea şi sustenabilitatea informaţiilor şi a afirmaţiilor făcute de autori, apartentnţa cercetărilor prezentate la aria geografică de interes ştiinţific – Dobrogea –, respectarea normelor de publicare impuse de revista Pontica, calitatea limbii străine în care a fost scris articolul sau rezumatul său etc.
                            <br/>
                            <br/>
                            <FontAwesomeIcon icon="check-square" className="icon_check" />Următoarea etapă constă în prezentarea recenziilor într-o şedinţă a colegiului de redacţie, precum şi a propunerilor de acceptare sau respingere a contribuţiilor ştiinţifice pentru numărul respectiv. După stabilirea sumarului volumului se trece la etapa de colaţionare şi tehno-redactare.
                            </p>   
                        </div>
                    </div>

            );
        }
        else{
            return (
                <div>
                </div>
                );
        }
    }
}
export default ProcesRecenzare;

