import React, { useRef, useEffect, useState, useInput } from 'react'

import QuillEditor from '../../../editor/QuillEditor';
import { Typography, Button, Form, message } from 'antd';
import axios from 'axios';
import { useSelector } from "react-redux";
import './CreatePage.css';
import './EditPage.css';
import  { Redirect } from 'react-router-dom'


const { Title } = Typography;

function EditPage(props) {
    const user = useSelector(state => state.user);
    

    const [content, setContent] = useState("")
    const [an, setAn]=useState("")
    const [autori, setAutori] = useState("")
    const [totiAutorii, setTotiAutorii] = useState([])
    const [nr_articol, setNrArticol] = useState("")
    const [titlu_articol, setTitluArticol ] = useState("")
    const [nr_pontica, setNrPontica  ] = useState("")
    const [text_articol, setTextArticol] = useState("")
    const [ files, setFiles] = useState([])
    const [articol, setArticol]=useState({})
    const [filename_intreg, setFilenameIntreg ]=useState('')
    const [filename, setFilename ]=useState('')
    const [id, setId]= useState({})
    const [writer, setWriter]=useState({})
    const [version, setVersion]=useState('')
    const [paginatie, setPaginatie] = useState('')
    const [id_pontica, setIdPontica]=useState('')
    const [titlu_articol_en, setTitluArticolEn]=useState('')
    const [sectiunea, setSectiunea ]=useState('')
    const [descriptori, setDescriptori  ]=useState('')
    const [limba, setLimba  ]=useState('')
    const [cuvinte_cheie, setCuvinteCheie  ]=useState('')
    const [cuvinte_cheie_en, setCuvinteCheieEn  ]=useState('')
    const [rezumat, setRezumat  ]=useState('')
    const [rezumat_en, setRezumatEn   ]=useState('')
    const [numar_pagini, setNumarPagini    ]=useState('')
    const [show_confirm_msg,setShowConfirmMsg]=useState(false)
    const [show_confirm_msg_editare_text_articol,setShowConfirmMsgEditareTextArticol]=useState(false)
    const [selectedAutori,setSelectedAutori]=useState([])
    const [autoriiDejaExistenti, setAutoriiDejaExistenti]=useState('')
    const [autori_textarea, setAutoriTextarea]=useState('')
    const [autor_nou, setAutorNou]=useState('')
    const [show_confirm_msg_adaugare_autor,setShowConfirmMsgAdaugareAutor]=useState(false)
    const [lista_autori_noi, setListaAutoriNoi]=useState([])
    const [autori_casuta, setAutoriCasuta] = useState([])
    const [text_area, setTextArea]=useState([])


    // const [autori_de_la_tastatura, setAutoriDeLaTastatura]=useState('')
    const form = useRef();

    const useForceUpdate = () => {
        const [count, setCount] = useState(0)
        
        const increment = () => setCount(prevCount => prevCount + 1)
        return [increment, count]
    }
    const [forceUpdate] = useForceUpdate()
    
    
    
    const handleConfirmMsg = (e) => {
        e.preventDefault();
        setShowConfirmMsg(true);
    };

    const handleConfirmMsgAdaugareAutor = (e) => {
        e.preventDefault();
        setShowConfirmMsgAdaugareAutor(true);
    };

    const handleCancelMsg=(e)=>{
        e.preventDefault();
        setShowConfirmMsg(false);
    }

    const handleCancelMsgAdaugareAutor=(e)=>{
        e.preventDefault();
        setShowConfirmMsgAdaugareAutor(false);
    }
    

    const handleCancelMsgEditareTextArticol=(e)=>{
        e.preventDefault()
        setShowConfirmMsgEditareTextArticol(false)
    }

    const handleConfirmMsgEditareTextArticol=(e)=>{
        e.preventDefault()
        setShowConfirmMsgEditareTextArticol(true)
    }


    const onEditorChange = (value) => {
        setContent(value)
        // setAn(value)
        console.log(content)
        // console.log(an)
    }


    const onFilesChange = (files) => {
        setFiles(files)
    }


    async function LoadArticleDetails(){
    
        console.log('window.location.pathname====',window.location.pathname)
        let myinput=window.location.pathname
        myinput=myinput.split('/')
        let nr_articol=myinput[myinput.length-1]
        console.log('nr_articol===',nr_articol)


        await axios.get('/api/blog/getBlog/'+nr_articol)
        .then(response=>{
            if(response.data.success){
                setArticol(response.data.articol)
            }
            else{
                alert('Couldnt get data')
            }
        })

        // await axios.get('/api/arhiva/getSearch/'+myinput)
        //     .then(response => {
        //         if (response.data.success) {
        //             // setArhiva(response.data.arhiva)
        //             // setCards(response.data.arhiva)
        //             setResults(response.data.search_result)
        //         } else {
        //             alert('Couldnt get search data')
        //         }
        //     })
    }

    async function getAuthors(){
        await axios.get('/api/arhiva/getAuthors')
        .then(response=>{
            if (response.data.success){
                setTotiAutorii(response.data.autori)
            }
        })
    }

    useEffect(()=>{
        LoadArticleDetails();
        getAuthors();


    },[])

    
    useEffect(()=>{
        if(articol != {}){
            console.log('articolul care trebuie editat ====',articol)

            setFilenameIntreg(articol.filename_intreg)
            setFilename(articol.filename)
            setId(articol._id)
            setWriter(articol.writer)
            setVersion(articol.__v)
            setPaginatie(articol.paginatie)
            setIdPontica(articol.id_pontica)
            setNrPontica(articol.nr_pontica)
            setAn(articol.an)
            setNrArticol(articol.nr_articol)
            setContent(articol.content)
            setAutori(articol.autori)
            setTitluArticol(articol.titlu_articol)
            setTitluArticolEn(articol.titlu_articol_en)
            setSectiunea(articol.sectiunea)
            setLimba(articol.limba)
            setCuvinteCheie(articol.cuvinte_cheie)
            setCuvinteCheieEn(articol.cuvinte_cheie_en)
            setRezumat(articol.rezumat)
            setRezumatEn(articol.rezumat_en)
            setNumarPagini(articol.numar_pagini)
            setDescriptori(articol.descriptori)
            
            // setTextArticol(articol.text_articol)
        }
    },[articol])

    useEffect(()=>{
        if(nr_articol!='' && nr_articol!=undefined){
            axios.get('/api/blog/getText/'+nr_articol)
                .then(res => {
                    if (res.data.success) {
                        if(res.data!='' && res.data!= null) setTextArticol(res.data.text_articol.text_articol)
                    } else {
                        alert('Couldnt get text')
                    }
                })
        }
    },[nr_articol])






    function onChangeDetails (event) {
        event.preventDefault();
        // setAn("");

        if (user.userData && !user.userData.isAuth) {
            return alert('Please Log in first');
        }

        let name=event.target.name
        let value=event.target.value

        console.log('name===',event.target.name)
        console.log('value===',event.target.value)

        if(name=='filename_intreg'){
            setFilenameIntreg(value)
        }
        else if(name=='filename'){
            setFilename(value)
        }
        else if (name=='nr_articol'){
            setNrArticol(value)
        }
        else if (name=='nr_pontica'){
            setNrPontica(value)
        }
        else if (name=='an'){
            setAn(value)
        }
        else if(name=='autori'){
            console.log('value===',value)
            // setAutoriTextarea(value)
        }
        else if(name=='adauga_autor_nou'){
            console.log('autor nou===',autor_nou)
            setAutorNou(value)
        }
        
        else if(name=='titlu_articol'){
            setTitluArticol(value)
        }
        else if(name=='content'){
            setContent(value)
        }
        else if(name=='writer'){
            setWriter(value)
        }
        else if(name=='paginatie'){
            setPaginatie(value)
        }
        else if(name=='id_pontica'){
            setIdPontica(value)
        }
        else if (name=='titlu_articol_en'){
            setTitluArticolEn(value)
        }
        else if (name=='sectiunea'){
            setSectiunea(value)
        }
        else if(name=='descriptori'){
            setDescriptori(value)
        }
        
        else if (name=='limba'){
            setLimba(value)
        }
        else if(name='cuvinte_cheie'){
            setCuvinteCheie(value)
        }
        else if(name=='cuvinte_cheie_en'){
            setCuvinteCheieEn(value)
        }
        else if(name=='rezumat'){
            setRezumat(value)
        }
        else if(name=='rezumat_en'){
            setRezumatEn(value)
        }
        else if(name=='numar_pagini'){
            setNumarPagini(value)
        }
    }



    function onSubmitAutorNou(event){
        event.preventDefault()

        axios.post('/api/blog/creare_autor',{'autor_nou':autor_nou})
        .then(
            (()=>{
                alert('Autorul a fost adaugat cu succes');
            })()
        )

    }


    function onSubmitEditareTextArticol(event){
        event.preventDefault()
        axios.post('/api/blog/editText/'+nr_articol, {'text_articol':text_articol})
        .then(
            ()=>{
                console.log('textul a fost adaugat cu success')
                alert('Textul a fost adaugat cu success')

            }
        )
    }

    function onSubmit(event){
        // event.preventDefault();

        if (user.userData && !user.userData.isAuth) {
            return alert('Please Log in first');
        }
        let articol_editat={
            '_id':articol._id,
            'filename_intreg':filename_intreg,
            'filename':filename,
            'nr_articol':nr_articol,
            'nr_pontica':nr_pontica,
            'an':an,
            'autori':autori_textarea,
            'titlu_articol':titlu_articol,
            'content':content,
            'writer':writer,
            '__v':version,
            'paginatie':paginatie,
            'id_pontica':id_pontica,
            'titlu_articol_en':titlu_articol_en,
            'sectiunea':sectiunea,
            'descriptori':descriptori,
            'limba':limba,
            'cuvinte_cheie':cuvinte_cheie,
            'cuvinte_cheie_en':cuvinte_cheie_en,
            'rezumat':rezumat,
            'rezumat_en':rezumat_en,
            'numar_pagini':numar_pagini,
        }



        axios.post('/api/blog/adaug_articol_in_articole_autor',lista_autori_noi)


        axios.post('/api/blog/editPost', articol_editat)
            .then(
                (()=>{
                    alert('Articolul a fost editat cu succes !');
                    console.log('articolul a fost editat cu succes')
                    window.location.reload();
                })()
            )
    }

    useEffect(()=>{
        // setAutoriiDejaExistenti(articol.autori)
        // setAutoriTextarea(articol.autori)
    },[articol.autori])


    useEffect(()=>{
        bifez_autorii_deja_existenti()
    },[totiAutorii])

    useEffect (()=>{
        // let autorii_finali=autoriiDejaExistenti

        console.log('am intrat in show_confirm_msg')
        console.log('autori_textarea===',autori_textarea)

        document.getElementById('autori_textarea').textContent=autori_textarea
    },[show_confirm_msg])



    useEffect(()=>{
        document.getElementById('autori_textarea').innerText=autori_textarea
        // console.log('am modificat textarea')
    },[autori_textarea])

    
    // useEffect(()=>{
    //     // parcurg lista de autori casuta si o transform intr-un string 
    //     console.log('autori_casuta=====',autori_casuta)

    //     for (let i=0; i<autori_casuta.length; i++){
    //         let autor=autori_casuta[i].trim()
    //         let pahar = autori_textarea
    //         setAutoriTextarea(pahar+autori_textarea)
    //     }
    // },[autori_casuta])




    async function handleCheckboxAutori(event,autor){
        console.log('adaug')
        if(event.target.checked){
            let numar_articol=articol.nr_articol.trim()
            if (numar_articol.charAt(0)=='0'){
                numar_articol=numar_articol.replace('0','')
            }

            //inainte de a adauga numarul la finalul listei, trebuie sa verific ca nu exista deja in lista, pt a nu adauga dubluri
            // let lista_numere_articole = autor.articole_autor.split(',')
            
            
            // let exista_deja=false
            // for (let j=0; j<lista_numere_articole.length; j++){
            //     let numar_curent=lista_numere_articole[j]
            //     numar_curent=numar_curent.trim()
            //     if (numar_curent===numar_articol){
            //         exista_deja=true
            //     }
            // }
            // if (!exista_deja){
                if (autor.articole_autor==''){
                    autor.articole_autor=numar_articol
                }
                else{
                    autor.articole_autor+=', '+numar_articol
                }

                //scot dublurile din autor.articole_autor

                // console.log('inainte, articole_autor===',autor.articole_autor)
                // autor.articole_autor=Array.from(new Set(autor.articole_autor.split(','))).toString()
                // console.log('dupa, articole_autor===',autor.articole_autor)


                let pahar=[...lista_autori_noi]
                pahar.push(autor)
                setListaAutoriNoi(pahar)
            // }

            let vect=autor.nume_autor.split(',')
            let autor_de_adaugat= vect[1]+' '+vect[0]
            let text_casuta=document.getElementById('autori_textarea').textContent
            if (text_casuta==''){
                text_casuta+=autor_de_adaugat.toUpperCase()
            }
            else{
                text_casuta+=','+autor_de_adaugat.toUpperCase()
            }
            // let pahar2=[...autori_casuta]
            // pahar2.push(text_casuta)
            // setAutoriCasuta(pahar2)
            setAutoriTextarea(text_casuta)
        }
        else{
        console.log('scot')

            let numar_articol=articol.nr_articol.trim()
            if (numar_articol.charAt(0)=='0'){
                numar_articol=numar_articol.replace('0','')
            }

            let pahar=[...lista_autori_noi]

            let contor=0
            console.log('autor debifat ====',autor)
            for (let j=0; j<pahar.length; j++){
                let autor_nou=pahar[j]
                if (autor_nou.id_autor===autor.id_autor){
                    
                    // console.log('autorul care trebuie scos din lista_autori_noi====',autor_nou)
                    // console.log('autor===',autor)
                    // console.log('autor_nou.id==',autor_nou.id_autor)
                    // console.log('autor.id==',autor.id_autor)




                    console.log('pahar inainte===',pahar)

                    // if(pahar.length>0){
                        // pahar.splice(j,1)
                        console.log('pahar dupa===',pahar)
                    // }




                    //1198, 1 ----> trebuie sa scot 1 de la sfarsit
                    // let re = /ab+c/i; // literal notation
                    // var regex = new RegExp("ReGeX" + testVar + "ReGeX");
                    // string.replace(regex, "replacement");

                    let myvar = ', '+numar_articol
                    let myvar2 = numar_articol
                    let myregex=new RegExp(`\\b${myvar}\\b`, 'gi')
                    let myregex2=new RegExp(`\\b${myvar2}\\b`, 'gi')

                    autor_nou.articole_autor=autor_nou.articole_autor.replace(myregex,'')
                    autor_nou.articole_autor=autor_nou.articole_autor.replace(myregex2,'')

                    console.log('autor_nou===',autor_nou)

                    contor++
                    break
                }
            }
            if(contor==0){
                // autor.articole_autor=autor.articole_autor.replace(', '+numar_articol,'')
                // autor.articole_autor=autor.articole_autor.replace(numar_articol,'')
                // pahar.push(autor)
                let myvar = ', '+numar_articol
                let myvar2 = numar_articol
                let myregex=new RegExp(`\\b${myvar}\\b`, 'gi')
                let myregex2=new RegExp(`\\b${myvar2}\\b`, 'gi')
                console.log('myregex2===',myregex2)
                autor.articole_autor=autor.articole_autor.replace(myregex,'')
                autor.articole_autor=autor.articole_autor.replace(myregex2,'')
                pahar.push(autor)
                console.log('am intrat pe varianta cu 0')
            }
            setListaAutoriNoi(pahar)
            let vect=autor.nume_autor.split(',')
            let autor_de_scos= vect[1]+' '+vect[0]
            autor_de_scos=autor_de_scos.toUpperCase()
            let text_casuta=document.getElementById('autori_textarea').textContent

            text_casuta=text_casuta.replace(','+autor_de_scos,'').replace(autor_de_scos,'')
            if(text_casuta[0]==','){
                text_casuta=text_casuta.substring(1)
            }
            setAutoriTextarea(text_casuta)
        }
    }

    
    useEffect(()=>{
        console.log('lista_autori_noi===',lista_autori_noi)
        
                //scot dublurile din autor.articole_autor
                
                for(let i=0; i<lista_autori_noi.length; i++){
                    let autor=lista_autori_noi[i]
                    autor.articole_autor=' '+autor.articole_autor

                    // console.log('inainte, articole_autor===',autor.articole_autor)
                    autor.articole_autor=Array.from(new Set(autor.articole_autor.split(','))).toString()
                    // console.log('dupa, articole_autor===',autor.articole_autor)

                    autor.articole_autor=autor.articole_autor.trim()

                    // let first_number =autor.articole_autor.split(',')[0]
                    

                    // let myvar = ' '+first_number
                    // let myvar2 = ', '+first_number
                    // let myregex=new RegExp(`\b${myvar}\b`, 'gi')
                    // let myregex2=new RegExp(`\b${myvar2}\b`, 'gi')

                    // autor.articole_autor=autor.articole_autor.replace(myregex,'')
                    // autor.articole_autor=autor.articole_autor.replace(myregex2,'')

                }


    },[lista_autori_noi])

    async function clickPeAutor(autor){
        let myCheckbox=document.getElementById('autor'+autor.id_autor) 
        
        if (myCheckbox!=null){
            if(myCheckbox.checked){
                // console.log('unchecked')
                myCheckbox.click()
            }
            else{
                // console.log('checked')
                myCheckbox.click()
            }
        }
    }



    const CasutaAutori=totiAutorii.map( (autor)=>{
        return <div className='view_all_si_checkbox'> 
            <input className='check_autor' id={'autor'+autor.id_autor} onChange= {(event)=>handleCheckboxAutori(event,autor)} type="checkbox" />
            <p className='autor' onClick={()=>clickPeAutor(autor)}> {autor.nume_autor.split(',')[0].toUpperCase()+', '+autor.nume_autor.split(',')[1]} </p> 
        </div>
    })




    async function bifez_autorii_deja_existenti(){
        if (articol.autori!=undefined && autori!=undefined){
            let autori_cu_virgula=articol.autori
            // console.log('autori cu virgula===',autori_cu_virgula)
        
            autori_cu_virgula=autori_cu_virgula.replace(', ',',').replace(',  ','')
            let lista_autori=autori_cu_virgula.split(',')

            console.log('lista_autori===',lista_autori)
    
            for (let i = 0; i < lista_autori.length; i++) {
                let numele_intreg=''
                const autor = lista_autori[i].trim();

                let vect = autor.split(' ')
                console.log('vect===',vect)
                // let prenume_autor=vect[0]
                let nume_autor=''
                if(vect[vect.length-1].includes('(') || vect[vect.length-1].includes(')')){
                    nume_autor=vect[vect.length-2]+' '+vect[vect.length-1]
                }
                else{
                    nume_autor=vect[vect.length-1]
                }
    
                // let restul_numelui=autor.replace(prenume_autor,'')
                let restul_numelui=autor.replace(nume_autor,'')
                // prenume_autor = prenume_autor.charAt(0).toUpperCase()+prenume_autor.substring(1).toLowerCase()


                // if (prenume_autor.includes('-')){
                //     let vect=prenume_autor.split('-')
                //     prenume_autor=''
                //     for (let j=0; j<vect.length-1; j++){
                //         prenume_autor+=vect[j].charAt(0).toUpperCase()+vect[j].substring(1).toLowerCase()+'-'
                //     }
                //     prenume_autor+=vect[vect.length-1].charAt(0).toUpperCase()+vect[vect.length-1].substring(1).toLowerCase()
                // }
                
                restul_numelui=restul_numelui.trim()
                // prenume_autor=prenume_autor.trim()
                nume_autor=nume_autor.trim()

                // restul_numelui=restul_numelui.charAt(0).toUpperCase()+restul_numelui.substring(1).toLowerCase()
                
                // numele_intreg=restul_numelui+', '+prenume_autor
                numele_intreg=nume_autor+', '+restul_numelui

                console.log('numele_intreg===',numele_intreg)


                if(CasutaAutori!=null){
                    for (let x=0; x<totiAutorii.length; x++){
                        let autor=totiAutorii[x]
                        if (autor.nume_autor.toLowerCase().replace(',','')===numele_intreg.toLowerCase().replace(',','')){
                            //inseamna ca am gasit autorul in lista de autori din baza de date
                            console.log('autor===',autor)
                            await clickPeAutor(autor)
                        }
                    }
                }
            }
        }
    }


    function display_autori(autori_cu_virgula){
        autori_cu_virgula=autori_cu_virgula.replace(', ',',').replace(',  ',',')
        let lista_autori=autori_cu_virgula.split(',')
        let lista_finala=[]

        for (let i = 0; i < lista_autori.length; i++) {
            let autor = lista_autori[i];
            autor=autor.trim()
            let vect = autor.split(' ')
            // let prenume_autor=vect[0]
            let nume_autor=vect[vect.length-1]


            // console.log('autor===',autor)
            // console.log('i=====',i)

            let restul_numelui=autor.replace(nume_autor,'')
            // prenume_autor=prenume_autor.toLowerCase().charAt(0).toUpperCase()
            // prenume_autor = prenume_autor.charAt(0).toUpperCase()+prenume_autor.substring(1).toLowerCase()
            nume_autor=nume_autor.toUpperCase()

            // if (prenume_autor.includes('-')){
            //     let vect=prenume_autor.split('-')

            //     prenume_autor=''
            //     for (let j=0; j<vect.length-1; j++){
            //         prenume_autor+=vect[j].charAt(0).toUpperCase()+vect[j].substring(1).toLowerCase()+'-'
            //     }
            //     prenume_autor+=vect[vect.length-1].charAt(0).toUpperCase()+vect[vect.length-1].substring(1).toLowerCase()



                // prenume_autor=vect[0].charAt(0).toUpperCase()+'-'+vect[1].charAt(0).toUpperCase()


            if (i==lista_autori.length-1){
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{restul_numelui+' '+nume_autor}</a>
                console.log('bartlo===',restul_numelui+' '+nume_autor)
                // let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{autor}</a>
                lista_finala.push(autor_link)
                // lista_finala.push(<span>,</span>)
            }
            else{
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{restul_numelui+' '+ nume_autor},</a>
                console.log('bartlo===',restul_numelui+' '+nume_autor)
                // let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{autor},</a>
                lista_finala.push(autor_link)
                // lista_finala.push(<span>,</span>)
            }
        }
        return lista_finala
    }








    const formularAdaugareAutorNou= <div className='adaugare_autor_nou'>
        <Form >
            <span className='adaugare_autor_nou_span'>Daca nu gasesti autorul dorit in lista, poti adauga un autor nou in baza de date. Acesta va fi de forma: Nume, Prenume</span>
            <div className='autor_nou_buton_si_input'>
                <button className= 'submit_autor_nou' onClick={handleConfirmMsgAdaugareAutor}>
                    Submit Autor Nou
                </button>

                <input 
                    htmlType="submit"
                    className="adaugare_autor_nou_input"
                    // onChange={onChangeDetails}
                    onChange={event=>setAutorNou(event.target.value)}
                    placeHolder="Popescu, Ion"
                    type="text"
                    name="adauga_autor_nou"
                />
            </div>

            {show_confirm_msg_adaugare_autor &&(
            <div className='mesaj_confirmare'>
                <br/>
                <p className='esti_sigur'>Esti sigur ca vrei sa creezi acest autor in baza de date?</p>{" "}
                <button className='submit_raspuns' onClick={(event)=>onSubmitAutorNou(event)}>
                    DA
                </button>
            
                <button className='submit_raspuns' onClick={(event)=>handleCancelMsgAdaugareAutor(event)}>
                    NU
                </button>
            </div>
            )}
        </Form>
    </div>


    const formularEditareTextArticol=<div>
        <Form className='submit_text_articol_div'>

            <div className='text_articol_div'>
                <label className='text_articol_label'>Text Articol</label>
                <textarea
                    rows='10'
                    cols='80'
                    htmlType="submit"
                    id='text_articol_textarea'
                    type="text" 
                    name="text_articol"
                    onChange={event=>{setTextArticol(event.target.value)}}
                    onKeyDown={(event)=>{onKeyDown(event,'text_articol_textarea',setTextArticol)}}
                    defaultValue={text_articol}
                />
            </div>

            
            <button className= 'submit_raspuns submit_text_articol_button' onClick={handleConfirmMsgEditareTextArticol}>
                SUBMIT Text Articol
            </button>

            {show_confirm_msg_editare_text_articol &&(
            <div className='mesaj_confirmare'>
                <br/>
                <p className='esti_sigur'>Esti sigur ca vrei sa editezi textul acestui articol?</p>{" "}
                <button className='submit_raspuns' onClick={(event)=>onSubmitEditareTextArticol(event)}>
                    DA
                </button>
            
                <button className='submit_raspuns' onClick={(event)=>handleCancelMsgEditareTextArticol(event)}>
                    NU
                </button>
            </div>
            )}
        </Form>

    </div>


    function handleSetState(mySetFunction, text_input, event) {
        // const value = event.currentTarget.value;
        console.log('mySetFunction===',mySetFunction)
        mySetFunction(text_input);
    }

    const onKeyDown = function(e, id_element, mySetFunction) {
        console.log('id_element===',id_element)
        if (e.keyCode === 8) {
            let text_input=''
            setTimeout(() => {
                text_input=document.getElementById(id_element).value
                // setDescriptori(text_input)
                handleSetState(mySetFunction, text_input, e)
            }, 100);
        }
    }
    
    

    return (
    <div className='editare_articol_big_container'>
        <h1 className='titlu_mare_editare_articol'> Editare Articol</h1>
        
        {/* <QuillEditor
            placeholder={"Start Posting Something"}
            onEditorChange={onEditorChange}
            onFilesChange={onFilesChange}
        /> */}



        {/* <Form    ref={form}  onSubmit={handleConfirmMsg}> */}
        <form ref={form}  onSubmit={handleConfirmMsg}>
            <div className='editare_articol_container'>

                <div className='anul'>
                    <label className='anul_label' for="">Anul</label>
                    <input 
                        className='anul_input'
                        id='anul_input'
                        size="large"
                        htmlType="submit"
                        onChange={event=>setAn(event.target.value)}
                        onKeyDown={(event)=>{onKeyDown(event,'anul_input',setAn)}}
                        defaultValue={articol.an}
                        type="text" 
                        name="an"
                    />
                </div>

                <div className='numar_articol_div'>
                    <label for="">Numar Articol</label>
                    <input 
                        htmlType="submit"
                        id="numar_articol_input"
                        // onChange={onChangeDetails}
                        onChange={event=>setNrArticol(event.target.value)}
                        onKeyDown={(event)=>{onKeyDown(event,'numar_articol_input',setNrArticol)}}
                        defaultValue={articol.nr_articol}
                        type="text" 
                        name="nr_articol"
                    />
                </div>

                <div className='titlu_articol_div'>
                    <label for="">Titlu Articol</label>
                    <textarea 
                        rows='5'
                        cols='40'
                        // size="200"   
                        htmlType="submit"
                        id="titlu_articol_input"
                        // onChange={onChangeDetails}
                        onChange={event=>setTitluArticol(event.target.value)}
                        onKeyDown={(event)=>{onKeyDown(event,'titlu_articol_input',setTitluArticol)}}
                        type="text" 
                        defaultValue={articol.titlu_articol}
                        name="titlu_articol"
                    >
                    </textarea>
                </div>

                <div className='titlu_articol_en_div'>
                    <label for="">Titlu Articol EN</label>
                    <textarea 
                        rows='5'
                        cols='40'
                        // size="200"   
                        htmlType="submit"
                        id="titlu_articol_en_input"
                        // onChange={onChangeDetails}
                        onChange={event=>setTitluArticolEn(event.target.value)}
                        onKeyDown={(event)=>{onKeyDown(event,'titlu_articol_en_input',setTitluArticolEn)}}
                        type="text" 
                        defaultValue={articol.titlu_articol_en}
                        name="titlu_articol_en"
                    >
                    </textarea>
                </div>

                <div className='autori_div'>
                    <label for="">Autori</label>
                    <textarea
                        rows='5'
                        cols='40'
                        htmlType="submit"
                        id='autori_textarea'
                        // onChange={onChangeDetails}
                        // defaultValue={articol.autori}
                        type="text" 
                        name="autori"
                    />


                    <div className='criteriu_de_cautare'>
                        
                        <span className='autori_titlu'>Autori</span>
                        <div className='lista_toti_autorii' >
                            {CasutaAutori}
                            {/* {articol && bifez_autorii_deja_existenti()} */}
                        </div>
                    </div>
                </div>


                <div className='numar_pontica_div'>
                    <label for="">Numar Pontica</label>
                    <input
                        htmlType="submit"
                        id="numar_pontica_input"
                        // onChange={onChangeDetails}
                        onChange={event=>setNrPontica(event.target.value)}
                        onKeyDown={(event)=>{onKeyDown(event,'numar_pontica_input',setNrPontica)}}
                        defaultValue={articol.nr_pontica}
                        type="text" 
                        name="nr_pontica"
                    />
                </div>

                <div className='filename_div'>
                    <label for="">Filename</label>
                    <input
                        htmlType="submit"
                        id="filename_input"
                        // onChange={onChangeDetails}
                        onChange={event=>setFilename(event.target.value)}
                        onKeyDown={(event)=>{onKeyDown(event,'filename_input',setFilename)}}
                        defaultValue={articol.filename}
                        type="text" 
                        name="filename"
                    />
                </div>

                <div className='id_pontica_div'>
                    <label for="">ID Pontica</label>
                    <input
                        htmlType="submit"
                        id="id_pontica_input"
                        // onChange={onChangeDetails}
                        onChange={event=>setIdPontica(event.target.value)}
                        onKeyDown={(event)=>{onKeyDown(event,'id_pontica_input',setIdPontica)}}
                        // placeholder={articol.id_pontica}
                        defaultValue={articol.id_pontica}
                        type="text" 
                        name="id_pontica"
                    />
                </div>


                <div className='sectiunea_div'>
                    <label>Sectiunea</label>
                    <input
                        htmlType="submit"
                        id="sectiunea_input"
                        // onChange={onChangeDetails}
                        onChange={event=>{setSectiunea(event.target.value)}}
                        onKeyDown={(event)=>{onKeyDown(event,'sectiunea_input',setSectiunea)}}

                        // defaultValue={articol.sectiunea}
                        defaultValue={articol.sectiunea}
                        name="sectiunea"
                        type="text" 
                    />
                </div>


                <div className='descriptori_div'>
                    <label>Descriptori</label>
                    <input
                        id="descriptori_input"
                        htmlType="submit"
                        // onChange={onChangeDetails}
                        onChange={event=>{setDescriptori(event.target.value)}}
                        onKeyDown={(event)=>{onKeyDown(event,'descriptori_input',setDescriptori)}}
                        defaultValue={articol.descriptori}
                        name="descriptori"
                        type="text" 
                    />
                </div>


                <div className='limba_div'>
                    <label>Limba de Redactare</label>
                    <input
                        id="limba_input"
                        htmlType="submit"
                        // onChange={onChangeDetails}
                        onChange={event=>{setLimba(event.target.value)}}
                        onKeyDown={(event)=>{onKeyDown(event,'limba_input',setLimba)}}
                        defaultValue={articol.limba}
                        name="limba"
                        type="text" 
                    />
                </div>


                <div className='cuvinte_cheie_div'>
                    <label for="">Cuvinte Cheie</label>
                    <textarea
                        rows='5'
                        cols='40'
                        htmlType="submit"
                        id='cuvinte_cheie_textarea'
                        type="text" 
                        name="cuvinte_cheie"
                        onChange={event=>{setCuvinteCheie(event.target.value)}}
                        onKeyDown={(event)=>{onKeyDown(event,'cuvinte_cheie_textarea',setCuvinteCheie)}}
                        defaultValue={articol.cuvinte_cheie}
                    />
                </div>


                <div className='cuvinte_cheie_en_div'>
                    <label for="">Cuvinte Cheie EN</label>
                    <textarea
                        rows='5'
                        cols='40'
                        htmlType="submit"
                        id='cuvinte_cheie_en_textarea'
                        type="text" 
                        name="cuvinte_cheie_en"
                        onChange={event=>{setCuvinteCheieEn(event.target.value)}}
                        onKeyDown={(event)=>{onKeyDown(event,'cuvinte_cheie_en_textarea',setCuvinteCheieEn)}}
                        defaultValue={articol.cuvinte_cheie_en}
                    />
                </div>


                <div className='rezumat_div'>
                    <label>Rezumat</label>
                    <textarea
                        rows='5'
                        cols='40'
                        htmlType="submit"
                        id='rezumat_textarea'
                        type="text" 
                        name="rezumat"
                        onChange={event=>{setRezumat(event.target.value)}}
                        onKeyDown={(event)=>{onKeyDown(event,'rezumat_textarea',setRezumat)}}
                        defaultValue={articol.rezumat}
                    />
                </div>


                <div className='rezumat_en_div'>
                    <label>Rezumat EN</label>
                    <textarea
                        rows='5'
                        cols='40'
                        htmlType="submit"
                        id='rezumat_en_textarea'
                        type="text" 
                        name="rezumat_en"
                        onChange={event=>{setRezumatEn(event.target.value)}}
                        onKeyDown={(event)=>{onKeyDown(event,'rezumat_en_textarea',setRezumatEn)}}
                        defaultValue={articol.rezumat_en}
                    />
                </div>


                <div className='paginatie'>
                    <label>Paginatie in Volum</label>
                    <input
                        id="paginatie_input"
                        htmlType="submit"
                        onChange={event=>{setPaginatie(event.target.value)}}
                        onKeyDown={(event)=>{onKeyDown(event,'paginatie_input',setPaginatie)}}
                        defaultValue={articol.paginatie}
                        name="paginatie"
                        type="text" 
                    />
                </div>


                <div className='numar_pagini_div'>
                    <label>Numar Pagini</label>
                    <input
                        id="numar_pagini_input"
                        htmlType="submit"
                        onChange={event=>{setNumarPagini(event.target.value)}}
                        onKeyDown={(event)=>{onKeyDown(event,'numar_pagini_input',setNumarPagini)}}
                        defaultValue={articol.numar_pagini}
                        name="numar_pagini"
                        type="text" 
                    />
                </div>


                





                <div className='editare_submit_div'>
                    <button
                        htmlType="submit"
                        className="editare_submit"
                        onSubmit={handleConfirmMsg}>
                        SUBMIT
                    </button>
                </div>



                {show_confirm_msg && (
                <div className='mesaj_confirmare'> 
                    <br/> 
                    <p className='esti_sigur'>Esti sigur ca vrei sa salvezi modificarile? </p> {" "}
                    <button type='submit' className='submit_raspuns' onClick={(event)=>onSubmit(event)}> 
                        DA
                    </button>

                    <button className='submit_raspuns' onClick={(event)=>handleCancelMsg(event)}> 
                        NU
                    </button>
                </div> 
                )}


                {formularEditareTextArticol}
                {formularAdaugareAutorNou}
            </div>
        {/* </Form> */}
        </form>
    </div>
    )
}

export default EditPage