import React, { useEffect, useState, useReducer } from 'react'
import axios from 'axios';
import { Card, Icon, Avatar, Col, Typography, Row,Button, Checkbox } from 'antd';
import Pagination from "react-js-pagination";
import './Arhiva.css'
import pdf_icon from '../../../images/pdf_icon.png';
import erihplus from '../../../images/erihplus.png';
import index_copernicus from '../../../images/index_copernicus.png';
import ez3 from '../../../images/ez3.png';
import scipio from '../../../images/scipio.png';
// import { StickyContainer, Sticky } from 'react-sticky';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArchive} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import arhiva_background from '../../../images/arhiva_background.jpg'
import Search from '../Search/Search.js'

library.add(faArchive)


const { Title } = Typography
const { Meta } = Card;

    
    
function Arhiva() {
    const [arhiva, setArhiva]=useState([])
    const [blogs, setBlogs]=useState([])
    const [listaListeArticole, setListaListeArticole]=useState([])
    const [articole, setArticole]=useState([])
    const [autori, setAutori]=useState([])
    const [autoriByNrRevista, setAutoriByNrRevista]=useState([])
    const [currentArticles, setCurrentArticles]=useState([])
    const [search_result, setSearchResult]=useState([])
    const [placeHolder, setplaceHolder]=useState([])
    const[activePage, setCurrentPage]=useState(1)
    const [cardsPerPage, setCardsPerPage] = useState(1)
    const[cards, setCards]=useState([])
    const[checkBox,setCheckbox]=useState(false)
    const[checkBoxAutori,setCheckboxAutori]=useState(true)
    const[viewAllAuthors, setViewAllAuthors]=useState(false)
    const [color,setColor]=useState('red')
    const [search, setSearch]=useState(0)
    const [show, setShow] = useState(false)
    const [currentCard, setCurrentCard]=useState([])
    const [revistaCurenta, setRevistaCurenta]=useState('')
    const [idRevistaCurenta, setIdRevistaCurenta]=useState(0)
    const [contorAfisare, setContorAfisare]=useState(2)
    const [currentIndex, setCurrentIndex]=useState(2)
    const [selectedVolumes, setSelectedVolumes]=useState([])
    const [selectedAutori, setSelectedAutori]=useState([])
    const [selectedCards, setSelectedCards]=useState([])
    const [listaArticoleFiltrate, setListaArticoleFiltrate]=useState([])
    const [filtruActiv, setFiltruActiv]=useState(false)
    const [searchInput, setSearchInput]=useState('')
    const [filtruReturnezToateArticoleleAutorului, setFiltruReturnezToateArticoleleAutorului]=useState(false)
    
    
    // revistele fara abverieri sunt: 1, 48-48 supplementum, 23, 24, 27, (astea sunt nr_pontica), iar in lista de mai jos le identific dupa id_pontica
    const listaRevisteCareNuAuAbrevieri=['1','47','22','23','26']  
    
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    function dynamicSortCrescator(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    function compare( a, b ) {
        if ( parseInt(a.nr_pontica) < parseInt(b.nr_pontica) ){
            return -1;
        }
        if ( parseInt(a.nr_pontica) > parseInt(b.nr_pontica) ){
            return 1;
        }
        return 0;
    }
    
    
    // const DelayComponent = () => {
        
    //     useEffect(() => {
    //         setTimeout(() => {
    //         setShow(true)
    //         }, 5000)
    //     }, [show])
    //     }




    async function getArhiva(){
        axios.get('/api/arhiva/getArhiva')
            .then(response => {
                if (response.data.success) {
                    setArhiva(response.data.arhiva)
                    setCards(response.data.arhiva)
                    let arhiva_server=response.data.arhiva

                    setRevistaCurenta(arhiva_server[0].filename)
                    setSelectedCards(response.data.arhiva)

                    // setCurrentCard(response.data.arhiva[response.data.arhiva.length-1].id_pontica)
                    // console.log('currentCards====',currentCards)
                    // get_authors_by_id_revista(currentCards)
                    // get_authors_by_id_revista(51)

                } else {
                    alert('Couldnt get blog`s lists')
                }
            })
    }

    async function getArticles(){
        await axios.get('/api/arhiva/getArticles')
        .then(response=>{
            if (response.data.success){
                setArticole(response.data.articole)
            }
        })
    }

    async function getAuthors(){
        await axios.get('/api/arhiva/getAuthors')
        .then(response=>{
            if (response.data.success){
                setAutori(response.data.autori)
            }
        })
    }



    async function get_authors_by_id_revista(currentCards){
        if (currentCards.length==1){
            // console.log('lungime 1')
            let id_revista=currentCards[0].id_pontica
            // console.log('id_revista====',id_revista)
            await axios.get('/api/arhiva/get_authors_by_id_revista/'+id_revista)
            .then(response=>{
                if (response.data.success){
                    setAutoriByNrRevista(response.data.autori_curenti)
                    
                    // console.log('autorii======',response.data.autori_curenti)
                    // console.log('response.data====',response.data)
                }
            })
        }
    }

    useEffect(() => {

        getArhiva();
        getArticles();
        getAuthors();

        


        // mybutton.click();
        // get_authors_by_id_revista(currentCards)


        // display_search_results();
        
            let x = document.querySelector('active')
            if (x) {
                x.click()
            }





            
        // let mybutton=document.getElementById('view_all_authors');
        // mybutton.click();
        // handlePageChange(1)
        // mybutton.click();
        

        // handleCheckbox();

    },[])



    useEffect(() => {
        if(arhiva.length>0) {
            handlePageChange(activePage)
            console.log('activePage====',activePage)
            console.log('arhiva====',arhiva)
        }
    }, [activePage]);
    // }, [arhiva]);


    
    // useEffect( ()=>{
        // if(search==0){
            
            // var children_list = document.querySelector(".pagination").childNodes;
            // if (children_list!= null && children_list !=undefined){
                
                
            //     children_list[0].innerText='';
            //     children_list[1].innerText='';
            //     children_list[children_list.length-1].innerText='';
            //     children_list[children_list.length-2].innerText='';
                
            //     children_list[0].className+='display_none';
            //     children_list[1].className+='display_none';
            //     children_list[children_list.length-1].className='display_none';
            //     children_list[children_list.length-2].className='display_none';
                
            //     children_list[0].className='sageti';
            //     children_list[1].className='sageti';
            //     children_list[children_list.length-1].innerHtml='sageti';
            //     children_list[children_list.length-2].innerHtml='sageti';





                
                // if (children_list[0]!=undefined)children_list[0].remove();
                // if (children_list[1]!=undefined)children_list[1].remove();
                // if (children_list[children_list.length-1]!=undefined) children_list[children_list.length-1].remove();
                // if (children_list[children_list.length-2]!=undefined)children_list[children_list.length-2].remove();
                
                
                
                // for (let i = 2; i < children_list.length-2; i++) {
                //     var child = children_list[i];
                    
                //     if (child!=undefined){
                //         let filename_details=arhiva[i-2].filename.split(' ')
                //         child.innerText=filename_details[0].toUpperCase()
                //         child.innerHTML += "&nbsp; &nbsp;"
                        
                //         child.innerText+=filename_details[1]
                //         child.innerHTML+="&nbsp; &nbsp;"
                //         // child.innerText+=filename_details[2]
                        
                //         var paginatie_an=document.createElement('div')
                //         paginatie_an.classList.add('paginatie_an')
                //         if (filename_details[3]==undefined){
                //             paginatie_an.innerText=filename_details[2]
                //         }
                //         else {
                //             let string_de_adaugat=filename_details[2]+' '+filename_details[3];
                //             paginatie_an.innerText=string_de_adaugat;
                //         }
                //         child.appendChild(paginatie_an)
                        
                    // }
                // }
            // }
        // }
    // })

    const indexOfLastCard = activePage*cardsPerPage;
    const indexOfFirstCard=indexOfLastCard-cardsPerPage;
    const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);
    



    function getArticole(){
        for (let i = 0; i < blogs.length; i++) {
            const blog= blogs[i];
            let nr_pontica=blog['nr_pontica']

            axios.get('/api/blog/getBlogs/'+nr_pontica)
            .then(response => {
                if (response.data.success) {
                    let blogs=response.data.blogs
                    let blogList=[]
                    
                    for (let i = 0; i<blogs.length; i++) {
                        const blog = blogs[i];
                        let currentBlog=
                        <div>
                            <ul>
                                <li>{blog['titlu_articol']}</li>
                            </ul>
                        </div>
                        blogList.push(currentBlog)
                    }
                    let paharList=[]
                    paharList=listaListeArticole
                    paharList.push(blogList)

                    setListaListeArticole(paharList)

                    // setBlogs(blogList)
                    // return blogList
                } 
                else 
                    {
                        alert('Couldnt get blog`s lists')
                    }
                }
                )  
        }
    }
    
    function display_casuta_autori(){
        let lista_autori=[]
        for (let i=0; i<autori.length; i++){
            let autor=autori[i]
            let div_autor=
                <div>
                    {autor['nume_autor']}
                </div>
            lista_autori.push(div_autor)
        }
        return {lista_autori}
    }








    function handleCheckboxVolume(event,revista){
        if(event.target.checked){
            let pahar=[...selectedVolumes]
            pahar.push(revista)
            setSelectedVolumes(pahar)
        }
        else{
            let pahar=[...selectedVolumes]
            for (let i = pahar.length - 1; i >= 0; --i) {
                if (pahar[i].id_pontica == revista.id_pontica) {
                    pahar.splice(i,1);
                }
            }
            setSelectedVolumes(pahar)
        }
    }



    function clickPeVolum(revista){
        let myCheckbox=document.getElementById(revista.id_pontica)

        if(myCheckbox.checked){
            console.log('unchecked')
            myCheckbox.click()
            
        }
        else{
            console.log('checked')
            myCheckbox.click()
        }
    }



    
    const CasutaVolume=arhiva.map( (revista)=>{
        return <div className='view_all_si_checkbox'> 

            <input className='check_volum' id={revista.id_pontica} onChange= {(event)=>handleCheckboxVolume(event,revista)} type="checkbox" />
            <p className='volum' onClick={()=>clickPeVolum(revista)}> {revista.filename} </p> 
        </div>
    })
    




    useEffect (()=>{
        if (selectedAutori.length==0){
            setListaArticoleFiltrate([])
            setFiltruActiv(false)
        }
    },[selectedAutori])

    useEffect (()=>{
        if (selectedVolumes.length==0){
            // setListaArticoleFiltrate([])
            // setFiltruActiv(false)
            setFiltruReturnezToateArticoleleAutorului(true)
        }
        else{
            setFiltruReturnezToateArticoleleAutorului(false)
        }
    },[selectedVolumes])




    function handleCheckboxAutori(event,autor){
        if(event.target.checked){
            let pahar=[...selectedAutori]
            pahar.push(autor)
            setSelectedAutori(pahar)
        }
        else{
            let pahar=[...selectedAutori]
            for (let i = 0; i < pahar.length; i++) {
                if (pahar[i].id_autor == autor.id_autor) {
                    pahar.splice(i,1);
                }
            }
            setSelectedAutori(pahar)
        }
    }

    function clickPeAutor(autor){
        let myCheckbox=document.getElementById('autor'+autor.id_autor)
        if(myCheckbox.checked){
            console.log('unchecked')
            myCheckbox.click()
        }
        else{
            console.log('checked')
            myCheckbox.click()
        }
    }



    const CasutaAutori=autori.map( (autor)=>{
        // return <a className='autor_link' href={"/articole/autori/"+autor.nume_autor}> {autor.nume_autor.split(',')[0].toUpperCase()+', '+autor.nume_autor.split(',')[1]} </a>

        return <div className='view_all_si_checkbox'> 
            <input className='check_autor' id={'autor'+autor.id_autor} onChange= {(event)=>handleCheckboxAutori(event,autor)} type="checkbox" />
            <p className='autor' onClick={()=>clickPeAutor(autor)}> {autor.nume_autor.split(',')[0].toUpperCase()+', '+autor.nume_autor.split(',')[1]} </p> 
        </div>
    })



    function handleRemoveRevista(revista){
        let pahar=[...selectedVolumes]
            for (let i = pahar.length - 1; i >= 0; --i) {
                if (pahar[i].id_pontica == revista.id_pontica) {
                    pahar.splice(i,1);
                }
            }
            setSelectedVolumes(pahar)
        document.getElementById(revista.id_pontica).click()
    }


    const revisteSelectate = selectedVolumes.map((revista) => {
        return <div className='view_all_si_checkbox'>
                
                <input className='check_volum' id={revista.id_pontica} onChange= {()=>handleRemoveRevista(revista)} type="checkbox" checked/>
                <p className='volum' onClick={()=>{handleRemoveRevista(revista)}}> {revista.filename} </p>
            </div>
    })



    function handleRemoveAutor(autor){
        let pahar=[...selectedAutori]
            for (let i = pahar.length - 1; i >= 0; --i) {
                if (pahar[i].id_autor == autor.id_autor) {
                    pahar.splice(i,1);
                }
            }
            setSelectedAutori(pahar)
        document.getElementById('autor'+autor.id_autor).click()
    }


    const autoriSelectati = selectedAutori.map((autor) => {
        return <div className='view_all_si_checkbox'>
            <input className='check_autor' id={'autor'+autor.id_autor}  onChange = {()=>handleRemoveAutor(autor)}  type="checkbox" checked/>
            <p className='autor' onClick={()=>handleRemoveAutor(autor)}> {autor.nume_autor} </p>
        </div>
    })



    const CasutaAutoriByNrRevista=autoriByNrRevista.map( (autor)=>{
        return <a className='autor_link' href={"articole/autori/"+autor.nume_autor}>
            {autor.nume_autor.split(',')[0].toUpperCase()+', '+autor.nume_autor.split(',')[1]}
        </a>
    })



    function display_autori(autori_cu_virgula){
        autori_cu_virgula=autori_cu_virgula.replace(', ',',').replace(',  ',',')
        let lista_autori=autori_cu_virgula.split(',')
        let lista_finala=[]

        for (let i = 0; i < lista_autori.length; i++) {
            let autor = lista_autori[i];
            autor=autor.trim()
            let vect = autor.split(' ')
            let prenume_autor=vect[0]


            // console.log('autor===',autor)
            // console.log('i=====',i)

            let restul_numelui=autor.replace(prenume_autor,'')
            // prenume_autor=prenume_autor.toLowerCase().charAt(0).toUpperCase()
            prenume_autor = prenume_autor.charAt(0).toUpperCase()+prenume_autor.substring(1).toLowerCase()
            if (prenume_autor.includes('-')){
                let vect=prenume_autor.split('-')

                prenume_autor=''
                for (let j=0; j<vect.length-1; j++){
                    prenume_autor+=vect[j].charAt(0).toUpperCase()+vect[j].substring(1).toLowerCase()+'-'
                }
                prenume_autor+=vect[vect.length-1].charAt(0).toUpperCase()+vect[vect.length-1].substring(1).toLowerCase()

                // prenume_autor=vect[0].charAt(0).toUpperCase()+'-'+vect[1].charAt(0).toUpperCase()
            }


            if (i==lista_autori.length-1){
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{prenume_autor+' '+restul_numelui}</a>
                // let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{autor}</a>
                lista_finala.push(autor_link)
                // lista_finala.push(<span>,</span>)
            }
            else{
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{prenume_autor +' '+ restul_numelui},</a>
                // let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{autor},</a>
                lista_finala.push(autor_link)
                // lista_finala.push(<span>,</span>)
            }
        }
        return lista_finala
    }


    function displayArticole(id_revista,index){
        // selectedAutori.includes(articol.nume_autori)

        let listaArticole=[]
        let divarticol=''
        
        let divTitlu=
        <div className='divTitlu'>
            <table className='div_titlu_header_articol'>
                <tbody>
                    <tr>
                        <td className='div_titlu_header_articol'>
                            Titlu Articol
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='div_titlu_header_autori'>
                <tbody>
                    <tr>
                        <td>
                            Autori
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        listaArticole.push(divTitlu);
        

        for (let i = 0; i < articole.length; i++) {
            const articol = articole[i];

            if (articol['id_pontica']==id_revista ){
                if (i%2==0){
                    divarticol=
                        <div className='divarticol_gri'>
                            <table className='titlu_articol'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <b><span>{articol['nr_articol']}</span></b>&nbsp;
                                            <a className="titlu_articol" href={"/blog/post/"+articol['_id']}>
                                                {articol['titlu_articol']}
                                            </a>
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>
                            <table className='autori'>
                                <tbody>
                                    <tr>
                                        <td >
                                            <div className='container_autor'>
                                                {display_autori(articol['autori'])}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                }else{
                    divarticol=
                        <div className='divarticol'>
                            <table className='titlu_articol'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <b><span>{articol['nr_articol']}</span></b>&nbsp;
                                            <a className="titlu_articol" href={"/blog/post/"+articol['_id']}>
                                                {articol['titlu_articol']} 
                                            </a>

                                        </td> 
                                    </tr>
                                </tbody>
                            </table>

                            <table className='autori'>
                                <tbody>
                                    <tr>
                                        <div className='container_autor'>
                                            {display_autori(articol['autori'])}
                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                }
            
                listaArticole.push(divarticol)
                setCurrentCard(id_revista)
            }
        }
        
        //daca e minimizat
        if(currentArticles.length === 0){
                setCurrentIndex(index)
                let listaArticole_fara_dubluri=listaArticole.filter(onlyUnique)

                setCurrentArticles([])
                setCurrentArticles(listaArticole_fara_dubluri)
                return listaArticole_fara_dubluri
        }

        //daca e maximizat
        else{

            //daca e acelasi buton
            if(index == currentIndex){
                setCurrentArticles([])
                return{}
            }

            //daca e alt buton
            else{
                let listaArticole_fara_dubluri=listaArticole.filter(onlyUnique)
                setCurrentIndex(index)
                setCurrentArticles(listaArticole_fara_dubluri)
                return{listaArticole_fara_dubluri}
            }
        }
    }



    // function handleCheckbox(/*event*/){
    //     // setCheckbox(event.target.checked)
    //     if (checkBox){
    //         setCardsPerPage(1);
    //     } else{
    //         setCardsPerPage(52)
    //         // var pagination=document.querySelector('.pagination');
    //         // pagination.body.style.backgroundColor = 'red';
    //         setColor('#fbfbfb');
    //         var children_list = document.querySelector(".pagination").childNodes;
    //         if (children_list !=undefined){
    //             if (children_list[2]!=undefined){
    //                 children_list[2].click();
    //             }
    //         }
    //     }
    // }

    




    function handleFiltru(){
        console.log('s-a aplicat filtrul')
        if(selectedAutori.length>0 && selectedVolumes.length==0){
            setFiltruReturnezToateArticoleleAutorului(true)
        }
        else if (selectedAutori.length==0  &&  selectedVolumes.length>0){
            setSelectedCards(selectedVolumes)
        }
        else{
            setSelectedCards(arhiva)
        }
        let lista_articole_filtrate = getFiltruAutori(selectedVolumes, selectedAutori)
        let lista_articole_filtrate_fara_dubluri=lista_articole_filtrate.filter(onlyUnique)

        lista_articole_filtrate_fara_dubluri.sort(compare)

        setListaArticoleFiltrate(lista_articole_filtrate_fara_dubluri)

        if( selectedAutori.length>0){
            setFiltruActiv(true)
        }else{
            setFiltruActiv(false)
        }
    }
    
    // function handleFiltruVolume(){
    //     if (selectedVolumes.length>0){
    //         setSelectedCards(selectedVolumes)
    //     }
    //     else{
    //         setSelectedCards(arhiva)
    //     }
    // }


    function handleCheckboxAutori2(event){
        setCheckboxAutori(event.target.checked)
        if (checkBoxAutori){
            setViewAllAuthors(true)
        } else{
            // setCardsPerPage(52)
            // var pagination=document.querySelector('.pagination');
            // pagination.body.style.backgroundColor = 'red';
            // setColor('#fbfbfb');

            // var children_list = document.querySelector(".afisare_dupa_autor").childNodes;
            // console.log(children_list)

            // if (children_list !=undefined){
            //     if (children_list[2]!=undefined){
            //         children_list[2].click();
            //     }
            // }
        }
    }


    const triggerSearchState = () => {
        setSearch(1)
    }

    // async function handleSubmit(event){
    //     event.preventDefault();
    //     triggerSearchState();
    
    //     var formData=new FormData(event.target)
    //     let myinput=formData.get('search_input')

    //     await axios.get('/api/arhiva/getSearch/'+myinput)
    //         .then(response => {
    //             if (response.data.success) {
    //                 // setArhiva(response.data.arhiva)
    //                 // setCards(response.data.arhiva)
    //                 setSearchResult(response.data.search_result)
    //             } else {
    //                 alert('Couldnt get search data')
    //             }
    //         })
    // }




    async function handleKeyDown(event){
        console.log('event handle key down')
        setSearchInput(event.target.value)

        if(event.keyCode===13 && event.shiftKey==false){
            event.preventDefault();
            console.log('am apasat enter')
            let submit_button=document.getElementsByClassName('search_link')[0];
            submit_button.click();
        }
    }

    async function handleChange(event){
        event.preventDefault();
        setSearchInput(event.target.value)

        // console.log('myinput===',myinput)

        // await axios.get('/api/arhiva/getSearch/'+myinput)
        //     .then(response => {
        //         if (response.data.success) {
        //             // setArhiva(response.data.arhiva)
        //             // setCards(response.data.arhiva)
        //             setSearchResult(response.data.search_result)
        //             setSearchInput(myinput)
        //             console.log('search result===',search_result)
        //         } else {
        //             alert('Couldnt get search data')
        //         }
        //     })
    }

    function mergi_inapoi_la_arhiva(){
        window.location.reload();
    }

    function display_search_results(){
        // DelayComponent()
        let mylist=[]
        
        if (search_result!=undefined){
            for (let i = 0; i < search_result.length; i++) {
                const element_curent = search_result[i];
                const articol=articole[element_curent.nr_articol-1]
                
                let indice=element_curent.nr_articol
                var regExp = /^0[0-9].*$/
                if (regExp.test(indice)) //verific daca incepe cu 0
                indice =indice.substring(1) // scot 0 de la inceput

                if (indice<1477){
                    // if(i %2==0){
                    //     let div_rezultat=
                    //         <div className='element_curent_div_gri'>
                    //             <a href={"https://minac.ro/pdf/articole/"+ articole[indice-1].filename}> 
                    //                 <span className='element_curent element_curent_titlu'> {articole[indice-1].titlu_articol}</span>
                    //                 <br/>
                    //                 <span className='element_curent'>Numar Articol: {element_curent.nr_articol}</span>
                    //                 <span className='element_curent'>Autori: {articole[indice-1].autori}</span>
                    //                 <span className='element_curent'>Volum: {articole[indice-1].nr_pontica}</span>
                    //                 <span className='element_curent'>An: {articole[indice-1].an}</span>
                                    
                    //             </a>
                    //         </div>

                    //     mylist.push(div_rezultat)
                    // }
                    // else{
                    //     let div_rezultat=
                    //         <div className='element_curent_div'>
                    //             <a href={"https://minac.ro/pdf/articole/"+ articole[indice-1].filename}> 
                    //             <span className='element_curent element_curent_titlu'> {articole[indice-1].titlu_articol}</span>
                    //             <br/>
                    //             <span className='element_curent'>Numar Articol :{element_curent.nr_articol}</span>
                    //             <span className='element_curent'>Autori :{articole[indice-1].autori}</span>
                    //             <span className='element_curent'>Volum :{articole[indice-1].nr_pontica}</span>
                    //             <span className='element_curent'>An: {articole[indice-1].an}</span></a>
                    //         </div>
                    //     mylist.push(div_rezultat)
                    // }



                    let div_rezultat=<div className='casuta_afisare_filtru'>
                        {i %2 == 0 && 
                        <div className='articole_filtrate_container'>
                            <div className='numar_articol_filtrat'>{articol.nr_articol}</div>
                            <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-articol.id_pontica].filename} </div>
                            <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>
                            <table className='autori_articol_filtrat'>
                                <tbody>
                                    <tr>
                                        <td >
                                            <div className='container_autor_filtrat'>
                                                {display_autori(articol.autori)}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}

                        {i %2 != 0 && 
                        <div className='articole_filtrate_container_gri'>
                            <div className='numar_articol_filtrat'>{articol.nr_articol}</div>
                            
                            <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-articol.id_pontica].filename} </div>
                            <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>
                            <table className='autori_articol_filtrat'>
                                <tbody>
                                    <tr>
                                        <td >
                                            <div className='container_autor_filtrat'>
                                                {display_autori(articol.autori)}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}
                    </div>

                    mylist.push(div_rezultat)
                }
            }
        }
        return mylist
    }

    function handle_view_all(){
        let mybutton=document.getElementById('view_all');
        mybutton.click();
    }

    function handle_view_all_authors(event){
        event.preventDefault()

        let mybutton=document.getElementById('view_all_authors');
        mybutton.click();

        console.log('am intrat in metoda view_all_authors()')
        get_authors_by_id_revista(currentCards)
    }

    function handleAfisareDupaAutor(event){
        // event.preventDefault();
    }


    const despre= <div className='despre_pontica'>
                    <p>
                        <b>Revista PONTICA</b>, anuar al Muzeului de Istorie Naţională şi Arheologie Constanţa, are o apariţie neîntreruptă din anul 1968. Tematica revistei este orientată spre studii de arheologie, istorie veche şi medie. Sumarul este structurat pe Studii şi comunicări, Cercetări arheologice, Note, Numismatică, Recenzii şi Note bibliografice.
                        În numerele revistei sunt analizate etape istorice importante în care zona pontică se defineşte ca zonă de contact între civilizaţiile egeo-mediteraneene şi transdanubiene.
                    </p>
                    <p>
                        Aceste circuite privesc, pe rând, culturile neolitice şi eneolitice; elemente de impact cultural greco-autohton; includerea zonei pontice în lumea romană ca etapă ce precede extinderea autorităţii romane la nord de Dunăre şi în spaţiul intracarpatic; rolul creştinismului şi romanitatea târzie. Sunt prezentate materiale epigrafice şi numismatice; reliefuri laice şi piese votive; elemente de arhitectură şi inventar creştin; forme de organizare urbană etc.
                    </p>
                    <p>
                        Semnatarii articolelor sunt nume consacrate în domeniu. Revista apare într-un tiraj de 300 de exemplare anual, fiecare număr conţinând între 300 şi 700 de pagini. Are schimburi cu cca. 170 de instituţii de profil din străinătate şi este inclusă în baze de date internaţionale (ERIH Plus, Index Copernicus, EZB – Electronic Journals Library, SCIPIO – Scientific Publishing & Information Online).
                    </p>

                    <a href="https://dbh.nsd.uib.no/publiseringskanaler/erihplus/periodical/info.action?id=482719">
                        <img className='iconite_descriere_arhiva' src={erihplus} alt=""/>
                    </a>
                    <a href="http://www.journals.indexcopernicus.com/Pontica,p10607,3.html">
                        <img className='iconite_descriere_arhiva' src={index_copernicus}alt=""/>
                    </a>
                    <a href="http://ezb.uni-regensburg.de/detail.phtml?bibid=AAAAA&colors=7&lang=en&jour_id=151817">
                        <img className='iconite_descriere_arhiva' src={ez3} alt=""/>
                    </a>
                    <a href="http://www.scipio.ro/web/pontica">
                        <img className='iconite_descriere_arhiva' src = {scipio} alt=""/>
                    </a>
                
                    <p>
                        Din anul 2008 Revista PONTICA este clasificată de Consiliul Naţional al Cercetării Ştiinţifice din Învăţământul Superior (CNCSIS) în categoria B+. În anul 2011, odată cu reorganizarea <a className='linkuri_descriere_arhiva' href="http://cncsis.gov.ro/">CNCSIS</a>și constituirea Consiliului Național al Cercetării Științifice (CNCS), revista a fost reevaluată rămânând în <a className='linkuri_descriere_arhiva' href="http://www.cncs-nrc.ro/wp-content/uploads/2021/01/categorii.Reviste.CNCS_.2020.2.pdf">categoria</a> B.
                        <br/>
                        <b>ISSN: 1013-4247</b>
                        <br/>
                        <b>e-ISSN: 2247-9341</b>
                    </p>
                </div>

    




    function getFiltruAutori(){
        // axios.post('/api/arhiva/get_filtru_autori/',{'selectedVolumes':selectedVolumes, 'selectedAutori':selectedAutori})
        // console.log('am facut requestul post')
        let lista_articole_filtrate=[]

        if(selectedVolumes.length>0){
            for (let i=0; i<selectedVolumes.length; i++){
                let revista_curenta=selectedVolumes[i];
        
                for(let j=0; j<articole.length; j++){
                    let articol_curent=articole[j]
                    if(articol_curent.id_pontica === revista_curenta.id_pontica){
                        
                        for (let k=0; k<selectedAutori.length; k++){
                            let autor_curent=selectedAutori[k];
                            
                            let lista_articole_autor = autor_curent.articole_autor.split(',')

                            for(let x=0; x<lista_articole_autor.length; x++){
                                if (articol_curent.nr_articol === lista_articole_autor[x].trim()){
                                    // console.log('am gasit unul')
                                    lista_articole_filtrate.push(articol_curent)
                                }
                            }
                        }
                    }
                }
            }
        }
        else{
            console.log('am intrat pe ramura in care nu am niciun volum selectat')
            let lista_articole_autori=[]
            
            for (let i =0; i<selectedAutori.length; i++){
                let autor_curent=selectedAutori[i]
                let articolele_autorului =autor_curent.articole_autor
                articolele_autorului=articolele_autorului.split(',')

                for (let j=0; j<articolele_autorului.length; j++){
                    let nr_articol=articolele_autorului[j]
                    nr_articol=nr_articol.trim()
                    lista_articole_autori.push(articole[parseInt(nr_articol)-1])
                }
            }
            lista_articole_filtrate=lista_articole_autori
            console.log('lista_articole_filtwrate===',lista_articole_filtrate)
        }
        return lista_articole_filtrate
    }



    const renderFiltruAutoriFaraTitlu=listaArticoleFiltrate.map( (articol_filtrat,index)=>{
        return <div>
                    {index %2 == 0 && 
                    <div className='articole_filtrate_container'>
                        <div className='numar_articol_filtrat'>{articol_filtrat.nr_articol}</div>
                        <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-articol_filtrat.id_pontica].filename} </div>
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol_filtrat._id}><div>{articol_filtrat.titlu_articol}</div></a></div>
                        <table className='autori_articol_filtrat'>
                            <tbody>
                                <tr>
                                    <td >
                                        <div className='container_autor_filtrat'>
                                            {display_autori(articol_filtrat.autori)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}

                    {index %2 != 0 && 
                    <div className='articole_filtrate_container_gri'>
                        <div className='numar_articol_filtrat'>{articol_filtrat.nr_articol}</div>
                        <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-articol_filtrat.id_pontica].filename} </div>
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol_filtrat._id}><div>{articol_filtrat.titlu_articol}</div></a></div>
                        <table className='autori_articol_filtrat'>
                            <tbody>
                                <tr>
                                    <td >
                                        <div className='container_autor_filtrat'>
                                            {display_autori(articol_filtrat.autori)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
            </div>
    })

    const renderFiltruAutori=(()=>{
        return  <div className = 'casuta_afisare_filtru' style={{  marginTop: 10 }}>
                    <div>{titluArticoleFiltrate()}</div>
                    <div>{renderFiltruAutoriFaraTitlu}</div>
                </div> 
    })


    const renderFiltruAutoriToateArticolele=(()=>{
        console.log('am intrat in render')
        //aici trebuie sa returnez toate articolele autorilor filtrati (indiferent de volumul in care apar articolele)
    })
    

    const titluArticoleFiltrate =()=> {
        return  <div className='articole_filtrate_container_gri'>
                    <div className='numar_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Nr. Articol</div>
                    <div className='titlu_volum_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Titlu Volum</div>
                    <div className='titlu_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Titlu Articol</div>
                    <div className='autori_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Autori</div>
                </div>
    }

























        // const renderCards = currentCards.map((revista, index) => {
        // const renderCards = cards.map((revista, index) => {
        const renderCards = selectedCards.map((revista, index) => {
        let revista_titlu='REVISTA ' + revista.filename.toUpperCase();

        // return  <Col className='col' key={index} lg={8} md={12} xs={24} >
        return  <Col className='col' key={index} >
            
            
            <Card
                hoverable
                className='card'
                actions={[
                    // <Icon className='icon' type="setting" key="setting" />,
                    // <Icon className='icon' type="edit" key="edit" />,
                    // <a href={`/blog/post/${blog._id}`}> <Icon type="ellipsis" key="ellipsis" /></a>,
                ]}
            >
                {/* <Meta
                    // avatar={ <Avatar src={revista.writer.image} />}
                    // title={revista.writer.name}
                    // title={blog.autori}
                    description=""
                /> */}

                <div className = 'casuta' style={{  overflowY: 'scroll', marginTop: 10 }}>
                    {/* <div dangerouslySetInnerHTML={{ __html: blog.content }}   /> */}
                    {/* <div dangerouslySetInnerHTML={{ __html: blog.autori }} /> */}
                        {/* <h2>Revista {revista.filename}.toUpperCase()</h2> */}
                        <span className='revista_titlu'>{revista_titlu}</span>


                        {/*{"nr_pontica": "52", "an": "2019", "writer": {"$oid": "600f0b0e67d7351c30b53c58"}, "content": "test", "filename": "Pontica 52 (2019)", "tip_volum": "SUPPLEMENTUM VI", "denumire_volum": "VARIA EPIGRAPHICA ET ARCHAEOLOGICA Volume dédié à mémoire de MARIA BĂRBULESCU", "nr_pontica_roman": "LII", "nr_pagini": 446, "nr_colite": null, "nr_planse": null, "nr_articole": 31, "ISSN": null, "e-ISSN": null, "editura_nume": null, "editura_localitate": null}]*/}
                        

                        <div className='continut_card'>

                            <a href={"https://minac.ro/pdf/reviste/"+revista.filename+".pdf"}>
                                <img className='coperti' src={"https://minac.ro/pdf/coperti/"+revista.filename+".jpg"} alt="" />
                            </a>
                            <div className='detalii_volum_container'>
                                {/* { revista.denumire_volum != undefined && <span className='detalii_volum'> Denumire Volum <span className='span_de_la_server'> {revista.denumire_volum}</span></span>} */}
                                <span className='detalii_volum alb'> An <span className='span_de_la_server'>{revista.an}</span></span>
                                <span className='detalii_volum gri'> Numar Volum <span className='span_de_la_server'>{revista.nr_pontica}</span></span>
                                <span className='detalii_volum alb'> Numar Pagini <span className='span_de_la_server'> {revista.nr_pagini}</span></span>
                                <span className='detalii_volum gri'> Numar Articole <span className='span_de_la_server'> {revista.nr_articole}</span></span>
                                <span className='detalii_volum alb'> Numar Volum <span className='span_de_la_server'> {revista.nr_pontica_roman}</span></span>
                                {/* {revista.tip_volum !=undefined && <span className='detalii_volum'> Tip Volum <span className='span_de_la_server'> {revista.tip_volum}</span></span>} */}
                                <div className='gri'>
                                    <div className='descarca'><b>Descarca Volumul</b></div>
                                    <a href={"https://minac.ro/pdf/reviste/"+revista.filename+".pdf"}>
                                        <span className='span_de_la_server'><img className='pdf_icon' src={pdf_icon} alt=""/></span>
                                    </a>
                                </div>

                                <div className='alb'>
                                    <div className='descarca'><b>Descarca Sumarul</b></div>
                                        <a href={"https://minac.ro/pdf/sumar/" + "1" + revista.filename + " - Sumar.pdf"}>
                                        <span className='span_de_la_server'><img className='pdf_icon' src={pdf_icon} alt=""/></span>
                                        </a>
                                </div>

                                {  !listaRevisteCareNuAuAbrevieri.includes(revista.id_pontica)  && 
                                <div className='gri'>
                                    <div className='descarca'><b>Descarca Lista Abrevierilor</b></div>
                                        <a href={"https://minac.ro/pdf/sumar/" + "2" + revista.filename + " - Lista prescurtărilor.pdf"}>
                                        <span className='span_de_la_server'><img className='pdf_icon' src={pdf_icon} alt=""/></span>
                                        </a>
                                </div>}
                            </div>
                        </div>


                        <br/>
                        
                        <button className='display_articole_button' type="submit" id={index} onClick={()=>displayArticole(revista.id_pontica,index) }>Afisati toate articolele</button>

                        {/* <br/>
                        content:{revista.content}
                        <br/> */}
                        
                    
                            
                        { (currentCard==revista.id_pontica) ? currentArticles :placeHolder }
                </div>

                        
                        {/* { articole=getArticole(revista.nr_pontica) }
                        {/* {articole} 
                        
                        <p id="nume_unic"></p> 
                        {finalList=getArticole(revista.nr_pontica)} 
                        {finalList}  */}
                {/* </div> */}
            </Card>
        </Col>
    })






    const handlePageChange = ( pageNumber ) => {
        console.log( `active page is ${ pageNumber }` );
        setCurrentPage( pageNumber )

        // handle_view_all_authors();

        if(arhiva.length>0){
            get_authors_by_id_revista(currentCards)
            // console.log('arhiva.length===',arhiva.length)
    
            let id_revista_curenta=arhiva.length-pageNumber+1
            setIdRevistaCurenta(id_revista_curenta)
            // console.log('id-ul revistei curenta=====', id_revista_curenta)
    
            // console.log('pontica curenta =======',arhiva[arhiva.length-id_revista_curenta].filename)
            let revista_curenta=arhiva[arhiva.length-id_revista_curenta].filename
            setRevistaCurenta(revista_curenta)
        }
        else{
            console.log('arhiva e inca 0')
        }
    };

    // if (search==0){

        return (
        // <div className='bigContainer' style={{  margin: '2rem auto' }}>
        <div className='bigContainer' style={{  margin: '2rem auto' }}>
            <div className='arhiva_header underline_container_redactie'>
                <div className='logo_and_title'>
                    <div className="separator linie">
                        <FontAwesomeIcon className= 'iconita_arhiva' icon="archive" style={{color:'#1dbc60'}} /> 

                        <h1>Arhiva Muzeului de Istorie Nationala si Arheologie Constanta</h1>
                        <span className="underline_recenzare"/>
                        <span style={{color:'#1dbc60'}}>✻</span>
                        <span className="underline_recenzare"/>
                    </div>
                </div>
            </div>

            <div className='smallContainer'>
                <div className='left_column'>
                    <div className='view_all_si_checkbox'>
                        <label className='checkbox'>

                            {/* <input id='view_all' onChange= {handleCheckbox} type="checkbox"  name="view_all" value="Afisati toate volumele" />

                            <button onClick={handle_view_all} className= 'view_all'>Afisati toate volumele</button> */}

                        </label>
                    </div>

                    <span className='volume'>Volume</span>
                    <div className='pagination_container'>
                        <div className='casutavolume'>
                            {CasutaVolume}
                        </div>
                    </div>


                    {/* {(checkBox)&&<div className="pagination_container" style={{backgroundColor: color}}>
                        <Pagination
                        activePage={ activePage }
                        itemsCountPerPage={ 100 }
                        totalItemsCount={ 0 }
                        pageRangeDisplayed={ 0 }
                        onChange={ handlePageChange }
                        value={activePage}
                        />
                    </div>
                    } */}

                    {/* {(!checkBox)&&<div className="pagination_container"> */}
                    {/* {<div className="pagination_container">
                        <Pagination
                        activePage={ activePage }
                        itemsCountPerPage={ 100 }
                        totalItemsCount={ cards.length }
                        pageRangeDisplayed={ 100 }
                        onChange={ handlePageChange } */}
                        {/* // value={activePage} */}
                        {/* /> */}
                    {/* </div>} */}


                    <span className='volume_selectate_titlu'>Volume Selectate </span>
                    <div className='volume_selectate'>
                        {revisteSelectate}
                        {/* <span>Revista Curenta: {revistaCurenta && revistaCurenta}</span> */}

                            {/* {let id_revista_curenta=arhiva.length-activePage+1
                                console.log('id-ul revistei curenta=====', id_revista_curenta)
                        
                                console.log('pontica curenta =======',arhiva[arhiva.length-id_revista_curenta].filename)} */}
                        {/* Revista Curenta: {activePage} */}
                        {/* {currentCards ? currentCards.nr_pontica : 'placeholder'} */}
                    </div>

                    {/* <form className='afisare_dupa_autor' onSubmit={handleAfisareDupaAutor}> */}
                        <span className='autori_titlu'>Autori</span>

                        <div className='lista_toti_autorii' >
                            {/* <button onClick={display_casuta_autori}></button> */}
                            {/* { checkBoxAutori ? CasutaAutori :  CasutaAutoriByNrRevista } */}
                            {CasutaAutori}


                            {/* {currentCards.length>0 && get_authors_by_id_revista(currentCards)} */}
                            {/* { arhiva.length>0 && CasutaAutoriByNrRevista.length>0 && console.log('casutaautori===',CasutaAutoriByNrRevista && console.log('aici')) } */}
                            
                        </div>

                        <span className='autori_selectati_titlu'>Autori Selectati  </span>
                        <div className='autori_selectati'>
                            {autoriSelectati}
                        </div>

                        {/* <div className='view_all_si_checkbox'>
                            <label className='checkbox'>
                                <input id="view_all_authors" onChange={handleCheckboxAutori} type="checkbox" name="view_all_authors"/>

                                <button onClick={handle_view_all_authors} className= 'view_all_authors'>Afisati toti autorii</button>
                            </label>
                        </div> */}

                    {/* </form> */}

                    <button className='filtreaza' onClick={()=>handleFiltru()}>
                        Aplica Filtre
                    </button>

                    {/* <form className='search' onSubmit={handleSubmit}>
                        <button className='search_button' onSubmit = {handleSubmit}>
                            Cautare in pdf
                        </button>
                        <input type="text" name='search_input' className='search_input'/> 
                    </form> */}


                    <form className='search' onKeyDown={(event)=>handleKeyDown(event)}>
                        <button type="submit" className='search_button' onKeyDown={(event)=>handleKeyDown(event)}>
                            <a className='search_link' href={'/search/'+searchInput} >
                                Cautare in pdf
                            </a>
                        </button>
                        
                        <input type="text" name='search_input' className='search_input' onChange={(event)=>handleChange(event)}/> 
                    </form>
                </div>
                <div className='CardsContainer'>
                    {/* <div className='header_container'> */}
                        {/* <img src={arhiva_background} alt=""/> */}
                    <div className='despreContainer'>
                            {despre}
                        <div className='despreOverlay'>
                        </div>
                    </div>

                    {selectedAutori.length==0 && renderCards}

                    {selectedAutori.length==0 && renderCards && filtruActiv===true}

                    {selectedAutori.length>0 &&  filtruActiv===false  &&  renderCards}

                    {/* {selectedAUtori.length>0 ? renderFiltruAutori} */}
                    {selectedAutori.length>0  && filtruActiv ===true && renderFiltruAutori()}
                    
                    { selectedAutori.length>0 && selectedVolumes.length==0 && filtruReturnezToateArticoleleAutorului ==true && filtruActiv==true }
                    {/* </div> */}
                </div>
            </div>
        </div>
        )
    // }




    // else if(search!=0){
    //     return(
    //         <div className=''>
    //             {
    //                 search!=0 && 
    //                 search_result!=undefined && 
    //                 <Search search={triggerSearchState} resultList={display_search_results()} results={search_result} />
    //             }
    //         </div>
    //     )
    // }
    // else {
    //     return(
    //         <div>
    //             loading...
    //         </div>
    //     )
    // }
} export default Arhiva


